import { NavLink } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";
import { useAppProvider } from "../Provider/AppProvider";

const MobileBottomMenu = () => {
    const { sidebarOpen, setSidebarOpen } = useAppProvider();
    const { translate } = translator();

    return (
        <div className=" !bg-black z-[90] md:hidden !fixed !bottom-[0px] !top-auto !w-full">
            <div className="bg-black w-full py-0 px-3 text-white flex items-center space-x-2">
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Dashboard}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            icon-name="monitor"
                            data-lucide="monitor"
                            className="lucide lucide-monitor m-auto mb-2"
                        >
                            <rect
                                x="2"
                                y="3"
                                width="20"
                                height="14"
                                rx="2"
                                ry="2"
                            ></rect>
                            <line x1="8" y1="21" x2="16" y2="21"></line>
                            <line x1="12" y1="17" x2="12" y2="21"></line>
                        </svg>
                        {translate("ACCOUNT_MENU", "DASHBOARD")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Assets}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            icon-name="wallet"
                            data-lucide="wallet"
                            className="lucide lucide-wallet m-auto mb-2"
                        >
                            <path d="M20 12V8H6a2 2 0 0 1-2-2c0-1.1.9-2 2-2h12v4"></path>
                            <path d="M4 6v12c0 1.1.9 2 2 2h14v-4"></path>
                            <path d="M18 12a2 2 0 0 0-2 2c0 1.1.9 2 2 2h4v-4h-4z"></path>
                            <line x1="9" y1="14" x2="15" y2="14"></line>
                        </svg>
                        {translate("ACCOUNT_MENU", "ASSETS")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <button
                        // to={`javascript:void(0)`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setSidebarOpen(!sidebarOpen);
                        }}
                        className="block text-[10px] text-center cursor-default !right-auto !relative !top-auto mobile-menu-toggler bottom-[10px] bg-black rounded-[50%] p-[10px]"
                    >
                        <img
                            src={UtilsService.getPulicImage(
                                "/dist/image/Logo_Fav_KP.png",
                            )}
                            className="w-[50px] m-auto"
                            alt="Logo Kalypay"
                        />
                    </button>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Cards}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-credit-card  m-auto mb-2"
                            viewBox="0 0 16 16"
                        >
                            <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"></path>
                            <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z"></path>
                        </svg>
                        {/* <KpCreditCardIcon /> */}
                        {translate("ACCOUNT_MENU", "CARDS")}
                    </NavLink>
                </div>
                <div className="w-1/5">
                    <NavLink
                        to={RoutesNames.Transactions}
                        className="block text-[10px] text-center leading-3 cursor-default"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="currentColor"
                            className="bi bi-card-list m-auto mb-2"
                            viewBox="0 0 16 16"
                        >
                            {" "}
                            <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h13zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13z" />{" "}
                            <path d="M5 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 5 8zm0-2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm0 5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm-1-5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zM4 8a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0zm0 2.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0z" />{" "}
                        </svg>
                        {translate("ACCOUNT_MENU", "TRANSACTIONS")}
                    </NavLink>
                </div>
            </div>
        </div>
    );
};

export default MobileBottomMenu;
