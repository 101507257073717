import { DatatableFilter } from "../../Models/DatatableFilter";
import Moderaton from "../../Models/Moderation";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";
const map = (item: any): Moderaton => {
    const mod = new Moderaton(
        item.id,
        item.adress,
        item.ville,
        item.codepostal,
        item.phone_country_code,
        item.phone,
        item.country,
        item.old_country,
        item.old_adress,
        item.old_ville,
        item.old_codepostal,
        item.old_phone_country_code,
        item.old_phone,
        item.status,
        item.created_at,
        item.type,
        item.user,
    );

    return mod;
};
const getAllFilter = async (filter: DatatableFilter): Promise<any> => {
    return axios
        .get(`/moderation/get-all`, { params: filter })
        .then(({ data }) => {
            const assets: Moderaton[] = data.data.map((item: any) => map(item));

            return {
                data: assets,
                count: data.count,
                currentPage: data.currentPage,
                nextPage: data.nextPage,
                prevPage: data.prevPage,
                lastPage: data.lastPage,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const changeStatus = async (data: any): Promise<any> => {
    const formData = new FormData();
    formData.append("status", data.status);
    return axios
        .put(`/moderation/change-status/${data.id}`, {
            status: data.status,
        })
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};
const getUserPendingModeration = async (): Promise<Moderaton> => {
    return axios
        .get(`/moderation/user-pending-moderation`)
        .then(({ data }) => {
            const assets: Moderaton[] = data.map((item: any) => map(item));

            const result: Moderaton = {};
            for (const asset of assets) {
                if (asset.adress) {
                    result.adress = asset.adress;
                }
                if (asset.ville) {
                    result.ville = asset.ville;
                }
                if (asset.codepostal) {
                    result.codepostal = asset.codepostal;
                }
                if (asset.country) {
                    result.country = asset.country;
                }
                if (asset.phone_country_code) {
                    result.phone_country_code = asset.phone_country_code;
                }
                if (asset.phone) {
                    result.phone = asset.phone;
                }
            }

            return result;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const ModerationService = {
    getAllFilter,
    changeStatus,
    getUserPendingModeration,
};

export default ModerationService;
