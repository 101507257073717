const RoutesNames = {
    Home: "/",
    Login: "/auth/login",
    SilentLogin: "/auth/silent/login",
    Register: "/auth/register",
    SilentRegister: "/auth/silent/register",
    Dashboard: "/dashboard",
    Assets: "/assets",
    Transactions: "/transactions",
    Payment: "/payments",
    Ecosystem: {
        Index: "/ecosystem",
        Perfectnodes: "/ecosystem/perfectnodes",
        Exchange: "/ecosystem/exchange",
        Kalysynthex: "/ecosystem/kalysynthex",
        KankouDao: "/ecosystem/kankou-dao",
        Kalyswap: "/ecosystem/kalyswap",
        FastNProduct: "/ecosystem/fastnproduct",
    },
    Cards: "/cards",
    Customer: "/customer",
    Contact: "/Contact",
    ColdWallet: "/cold-wallet",
    Iban: "/iban",
    Earning: "/earning",
    Services: "/services",
    Loan: "/loan",
    Loaylty: "/loyalty",
    FindAgent: "/find-agent",
    Setting: {
        Index: "/settings",
        General: "/settings/general",
        Security: "/settings/security",
        Referrals: "/settings/referrals",
        Position: "/settings/position",
        Aplications_web: "/settings/application-web",
        Qr_code_merchant: "/settings/qr-code-merchant",
    },
    Partners: "/partners",
    Faq: "/faq",
    CardOrder: "/orders",
    Accounting: {
        Index: "/accounting",
        Balance: "/accounting/balance",
        Fee: "/accounting/free",
    },
    Moderation: "/moderation",
    Point_of_sale: "/point-of-sale",
    Commercial: "/commercial",
    Sales_manager: "/sales-manager",
    // Coldwallet: {
    //     Index: "/cold-wallet",
    // },
    Profil: "profil/:uid",
    PaymentKp: "/payment-checkout",
    DepositVirementFA: "/virement",
    Support: "/support",
    Maintenance: "/maintenance",
    Branding: "/branding",
    MentionLegale: "/mention-legale",
    CGV: "/cgv",
    Confidentialite: "/confidentialite",
    KycAml: "/kyc-aml",
    OurFee: "/our-fee",
};

export default RoutesNames;
