import { Menu, Transition } from "@headlessui/react";
import { useAppDispatch } from "../../../globalState/hook";
import useLanguage from "../../../Services/Hooks/useLanguage";
import translator from "../../../Components/Extra/Translation/Translate";
import {
    ChangeAppLanguage,
    Language,
} from "../../../globalState/reducers/Language.reducer";
import UtilsService from "../../../Services/UtilsService";

export default function DropdownLanguage({
    align,
}: {
    align?: "left" | "right" | "center";
}) {
    const dispatch = useAppDispatch();
    const { language } = useLanguage();
    const { translate } = translator();

    const languageUrl = (lang: Language) => {
        switch (lang) {
            case Language.EN:
                return "/dist/image/language/united-states.png";
            case Language.ES:
                return "/dist/image/language/espagne.png";
            default:
                return "/dist/image/language/france.png";
        }
    };

    return (
        <Menu as="div" className="relative inline-flex">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${
                            open && "bg-slate-200"
                        }`}
                    >
                        <span className="sr-only">Language</span>
                        <img
                            alt="User Dropdown"
                            className=" w-4 h-4 object-fill "
                            src={UtilsService.getPulicImage(
                                languageUrl(language),
                            )}
                        />
                    </Menu.Button>
                    <Transition
                        className={`origin-top-right z-10 absolute top-full -mr-48 xs:mr-[-128px]  bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
                            align === "right" ? "right-0" : "left-0"
                        }`}
                        enter="transition ease-out duration-200 transform"
                        enterFrom="opacity-0 -translate-y-2"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-out duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Menu.Items as="ul" className="focus:outline-none">
                            <Menu.Item
                                as="li"
                                className="border-b border-slate-200 dark:border-slate-700 last:border-0"
                            >
                                <button
                                    className={`flex py-2 px-4`}
                                    onClick={() =>
                                        dispatch(
                                            ChangeAppLanguage({
                                                language: Language.FR,
                                            }),
                                        )
                                    }
                                >
                                    <img
                                        alt="User Dropdown"
                                        className="w-6 h-6 object-fill mr-2"
                                        src={UtilsService.getPulicImage(
                                            languageUrl(Language.FR),
                                        )}
                                    />
                                    <span className="pt-[2px]">
                                        {/* {translate("Head", "French")}  */}
                                        FR
                                    </span>
                                </button>
                            </Menu.Item>
                            <Menu.Item
                                as="li"
                                className="border-b border-slate-200 dark:border-slate-700 last:border-0"
                            >
                                <button
                                    className={`flex py-2 px-4`}
                                    onClick={() =>
                                        dispatch(
                                            ChangeAppLanguage({
                                                language: Language.EN,
                                            }),
                                        )
                                    }
                                >
                                    <img
                                        alt="User Dropdown"
                                        className=" w-6 h-6 object-fill mr-2"
                                        src={UtilsService.getPulicImage(
                                            languageUrl(Language.EN),
                                        )}
                                    />
                                    <span className="pt-[2px]">
                                        {/* {translate("Head", "English")}  */}
                                        EN
                                    </span>
                                </button>
                            </Menu.Item>
                            <Menu.Item
                                as="li"
                                className="border-b border-slate-200 dark:border-slate-700 last:border-0"
                            >
                                <button
                                    className={`flex py-2 px-4`}
                                    onClick={() =>
                                        dispatch(
                                            ChangeAppLanguage({
                                                language: Language.ES,
                                            }),
                                        )
                                    }
                                >
                                    <img
                                        alt="User Dropdown"
                                        className=" w-6 h-6 object-fill mr-2"
                                        src={UtilsService.getPulicImage(
                                            languageUrl(Language.ES),
                                        )}
                                    />
                                    <span className="pt-[2px]">
                                        {/* {translate("Head", "Spain")}  */}
                                        ES
                                    </span>
                                </button>
                            </Menu.Item>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}
