import { useEffect, useState } from "react";
import { useAppSelector } from "../globalState/hook";
import Loader from "../Layouts/Loader/Loader";
import AppAuthentication from "../Services/Authentication/AppAuthentication";
import useAuthentication from "../Services/Authentication/useAuthentication";

type AuthenticationProviderProps = {
    children?: JSX.Element;
};
const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
    const { user } = useAuthentication();
    const { authChecking } = useAppSelector((store) => store.auth);
    const { getInformationAsync } = AppAuthentication();
    const [checkAuthentication, setCheckAuthentication] =
        useState<boolean>(true);

    useEffect(() => {
        if (!user) {
            getInformationAsync();
        }
        setCheckAuthentication(false);
    }, [user]);

    if (authChecking || checkAuthentication) {
        return <Loader />;
    }
    return <>{children}</>;
};

export default AuthenticationProvider;
