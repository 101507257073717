import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import RoutesNames from "../../../Services/RoutesNames";
import UtilsService from "../../../Services/UtilsService";
import Illustration from "../../images/footer-illustration.png";

export default function KalypayFooter() {
    const { translate } = translator();
    return (
        <footer className="relative">
            {/* Bg */}
            <div
                className="absolute inset-0 bg-slate-800 -z-10"
                aria-hidden="true"
            />

            {/* Illustration */}
            <div
                className="absolute bottom-0 left-1/2 -translate-x-1/2 pointer-events-none -z-10"
                aria-hidden="true"
            >
                <img
                    className="max-w-none"
                    src={Illustration}
                    alt="Footer illustration"
                />
            </div>

            <div className="max-w-6xl mx-auto px-4 sm:px-6">
                {/* Blocks */}
                <div className="grid sm:grid-cols-12 lg:grid-cols-10 gap-8 pt-8 pb-12 border-t border-slate-700">
                    {/* 1st block */}
                    <div className="sm:col-span-12 lg:col-span-2 lg:max-w-xs">
                        {/* Logo */}
                        <NavLink className="block" to={RoutesNames.Dashboard}>
                            <img
                                src={UtilsService.getPulicImage(
                                    "/dist/image/Logo-Kalypay-HD-Original-PNG.png",
                                )}
                                className="h-[50px] w-auto"
                                alt="Kalypay Logo"
                            />
                        </NavLink>
                    </div>

                    {/* 2nd block */}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "INFORMATION")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.MentionLegale}
                                >
                                    {translate("FOOTER", "MENTION")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.CGV}
                                >
                                    {translate("FOOTER", "CGV")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.Confidentialite}
                                >
                                    {translate("FOOTER", "PRIVACY")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.KycAml}
                                >
                                    KYC / AML
                                </NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* 3rd block */}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "OUR_SOLUTION")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalypay.com/#transfert"
                                >
                                    {translate("My_Transactions", "Transfert")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalypay.com/#swap"
                                >
                                    {translate("My_Transactions", "Swap")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalypay.com/#earning"
                                >
                                    {translate("My_Transactions", "Staking")}
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalypay.com/#cards"
                                >
                                    {translate("ACCOUNT_MENU", "CARDS")}
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* 4th block */}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "EN_SAVOIR")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="#0"
                                >
                                    {translate("FOOTER", "CONTACT_US")}
                                </a>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.Partners}
                                >
                                    {translate("FOOTER", "BE_AGENT")}
                                </NavLink>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.OurFee}
                                >
                                    {translate("FOOTER", "OUR_FEE")}
                                </NavLink>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalyssi.com/roadmap/"
                                    target="_blank"
                                >
                                    Roadmap
                                </a>
                            </li>
                        </ul>
                    </div>

                    {/* 5th block */}
                    <div className="sm:col-span-6 md:col-span-3 lg:col-span-2">
                        <h6 className="text-sm text-slate-100 font-bold mb-3">
                            {translate("FOOTER", "GROUP_INFO")}
                        </h6>
                        <ul className="text-sm font-[450] space-y-2">
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalyssi.com/"
                                    target="_blank"
                                >
                                    Kalyssi
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://kalycoin.io/"
                                    target="_blank"
                                >
                                    Kalycoin
                                </a>
                            </li>
                            <li>
                                <a
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    href="https://solidity.finance/audits/Kalycoin/"
                                    target="_blank"
                                >
                                    {translate("FOOTER", "AUDIT")}
                                </a>
                            </li>
                            <li>
                                <NavLink
                                    className="text-slate-400 hover:text-kalypay-500 transition duration-150 ease-in-out"
                                    to={RoutesNames.Branding}
                                >
                                    {translate("FOOTER", "BRANDING")}
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Bottom area */}
                <div className="pb-4 md:pb-8">
                    <div className="text-xs text-center text-slate-100">
                        © 2017-2023{" "}
                        <a
                            className="font-medium hover:text-kalypay-500 transition duration-150 ease-in-out"
                            href="#0"
                        >
                            KalyPay,
                        </a>{" "}
                        {translate("FOOTER", "COPYRIGHT")}
                    </div>
                </div>
            </div>
        </footer>
    );
}
