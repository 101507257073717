import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import Transaction, {
    WalletHistoryStatus,
    WalletHistoryType,
} from "../../Models/Transaction";
import TransactionService from "../../Services/Wallets/TransactionService";
import UtilsService, { formatAmount } from "../../Services/UtilsService";
import { DatatableFilter } from "../../Models/DatatableFilter";
import DataTableWithPaginatedData, {
    DataTableColums,
} from "../Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import TransactionResponsiveTable, {
    DataResponsiveTableColums,
} from "../Extra/TableResponsive/TransactionResponsiveTable";
import ICSVTransaction from "../../Models/TransactionCSV";
import * as XLSX from "xlsx";
import translator from "../Extra/Translation/Translate";

export const CsvCont = () => {
    const [search, setSearch] = useState<string>("");
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");

    const getSwapinAmount = (description: string) => {
        if (description.includes("vers")) {
            const amount = description.split("vers ")[1].split(" ")[0];
            return amount;
        }
        // if (description.includes("en")) {
        //     const amount = description.split("en ")[1].split(" ")[0];
        //     return amount;
        // }
        return "";
    };

    const map = (item: Transaction): ICSVTransaction => {
        const type = TransactionService.formatType(item.type);
        const date = UtilsService.getBeautifulFormatedDateWithTime(
            new Date(item.date),
        );

        const received_amount =
            type === "Dépôt"
                ? `${item.amount}`
                : item.type === "swapout"
                ? getSwapinAmount(item.description)
                : "";
        const sended_amount =
            type === "Retrait" || type === "Échange" ? `${item.amount}` : "";
        const transaction = new ICSVTransaction(
            type,
            date,
            received_amount,
            sended_amount,
            item.user_wallet.abbreviation,
            item.fee || 0,
            item.description,
            "",
        );

        transaction.type = TransactionService.formatType(item.type);
        transaction.date = item.date;
        transaction.wallet =
            type === "Retrait"
                ? ""
                : type === "Échange"
                ? item.target?.abbreviation || ""
                : item.user_wallet.abbreviation;
        transaction.crypto_out =
            item.type === "staking"
                ? item.user_wallet.abbreviation
                : type === "Échange"
                ? item.user_wallet.abbreviation
                : item.target?.abbreviation || "";
        transaction.fee = item.fee || 0;
        transaction.crypto_fee = "";
        transaction.platform = "Perfectnodes";
        transaction.description = item.description;
        transaction.label = "";
        return transaction;
    };

    const exportToXlsx = async () => {
        const filterCSV: DatatableFilter = {
            page: 1,
            size: 99999,
            search: search,
        };

        if (startDate) {
            filterCSV.start_date = startDate;
        }
        if (endDate) {
            filterCSV.end_date = endDate;
        }

        const res_csv = await TransactionService.getUserTransactionsPaginate(
            filterCSV,
            direction === "ASC" ? "1" : undefined,
        );

        const filtered_res_csv: Transaction[] = res_csv.data.filter(
            (elt: Transaction) =>
                elt.status === WalletHistoryStatus.COMPLETED &&
                elt.type !== WalletHistoryType.SWAPIN &&
                elt.type !== WalletHistoryType.STOP_STAKING &&
                elt.type !== WalletHistoryType.PURCHASE_FIDELITY,
        );

        if (filtered_res_csv) {
            const assetsCSV: ICSVTransaction[] = filtered_res_csv.map((item) =>
                map(item),
            );

            const setColumnWidth = (worksheet: XLSX.WorkSheet) => {
                const worksheetCol = worksheet["!cols"] || [];
                worksheetCol[0] = { wpx: 50 * 1.3 };
                worksheetCol[1] = { wpx: 100 * 1.3 };
                worksheetCol[2] = { wpx: 100 * 1.3 };
                worksheetCol[3] = { wpx: 100 * 1.3 };
                worksheetCol[4] = { wpx: 100 * 1.3 };
                worksheetCol[5] = { wpx: 110 * 1.3 };
                worksheetCol[6] = { wpx: 50 * 1.3 };
                worksheetCol[7] = { wpx: 110 * 1.3 };
                worksheetCol[8] = { wpx: 70 * 1.3 };
                worksheetCol[9] = { wpx: 210 * 1.3 };
                worksheetCol[10] = { wpx: 70 * 1.3 };
                worksheet["!cols"] = worksheetCol;
            };

            const columns = [
                { label: "Type", key: "type" },
                { label: "Date", key: "date" },
                { label: "Montant reçu", key: "received_amount" },
                { label: "Monnaie ou jeton reçu", key: "wallet" },
                { label: "Montant envoyé", key: "sended_amount" },
                { label: "Monnaie ou jeton envoyé", key: "crypto_out" },
                { label: "Frais", key: "fee" },
                { label: "Monnaie ou jeton des frais", key: "crypto_fee" },
                { label: "Plateforme", key: "platform" },
                { label: "Description", key: "description" },
                { label: "Label", key: "label" },
            ];

            const sheetData = [
                columns.map((column) => column.label), // Titres de colonne
                ...assetsCSV.map((item) =>
                    columns.map(
                        (column) => item[column.key as keyof ICSVTransaction],
                    ),
                ), // Données
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            const workbook = XLSX.utils.book_new();
            setColumnWidth(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

            // Génère un nom de fichier unique en ajoutant la date et l'heure actuelles
            const date = new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/[-:]/g, "");
            const fileName = `transactions_${date}.xlsx`;

            XLSX.writeFile(workbook, fileName, { cellStyles: true });
        }
    };

    const handleExportClick = (e: any) => {
        e.preventDefault();
        exportToXlsx();
    };

    return (
        <button
            type="button"
            className="btn bg-transparent !border-none sm:w-[20%] md:w-auto lg:w-auto xl:w-auto h-[42px] mt-[5px] !p-0"
            onClick={(e) => handleExportClick(e)}
        >
            <img
                src={UtilsService.getPulicImage("/dist/image/icon_image.png")}
                className="h-full w-auto object-fill"
                alt=""
            />
        </button>
    );
};

const TransactionTable = () => {
    const [assets, setAssets] = useState<Array<Transaction>>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [increment, setIncrementor] = useState<number>(0);
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const [filterIndex, setFilterIndex] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOrderByDate, setIsOrderByDate] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const { translate } = translator();

    const setDates = (date1?: string, date2?: string) => {
        if (date1 && date2) {
            setStartDate(new Date(date1));
            setEndDate(new Date(date2));
        } else {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };

    const colums: DataTableColums[] = [
        {
            index: "date",
            title: "Date",
            order: true,
            sort: () => {
                setDirection(direction === "ASC" ? "DESC" : "ASC");
            },
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "Description",
            content: (row: Transaction) => {
                return row.description;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = [
                    WalletHistoryType.WITHDRAW,
                    WalletHistoryType.WITHDRAW_CRYPTO,
                    WalletHistoryType.WITHDRAW_KALYPAY,
                    WalletHistoryType.TRANSFERT_OUT,
                    WalletHistoryType.SWAPOUT,
                    WalletHistoryType.ORDER_PACKBOOSTER,
                    WalletHistoryType.PURCHASE_FIDELITY,
                    WalletHistoryType.WITHDRAW_MANUAL,
                ];

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;

                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const column: DataResponsiveTableColums[] = [
        {
            index: "date",
            title: "Date",
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "Description",
            content: (row: Transaction) => {
                return row.description;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = [
                    WalletHistoryType.WITHDRAW,
                    WalletHistoryType.WITHDRAW_MANUAL,
                    WalletHistoryType.WITHDRAW_CRYPTO,
                    WalletHistoryType.WITHDRAW_KALYPAY,
                    WalletHistoryType.TRANSFERT_OUT,
                    WalletHistoryType.SWAPOUT,
                    WalletHistoryType.ORDER_PACKBOOSTER,
                    WalletHistoryType.PURCHASE_FIDELITY,
                ];

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;
                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const getUserTransactionsPaginate = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        if (startDate) {
            filter.start_date = startDate;
        }
        if (endDate) {
            filter.end_date = endDate;
        }

        try {
            setIsOrderByDate(!isOrderByDate);
            const res = await TransactionService.getUserTransactionsPaginate(
                filter,
                direction === "ASC" ? "1" : undefined,
            );
            setAssets([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            setIsLoading(false);
        }
    }, [increment]);

    useEffect(() => {
        setIncrementor(increment + 1);
    }, [currentPage, perPage, search, startDate, endDate]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [startDate, endDate]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [direction]);

    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);
    useEffect(() => {
        getUserTransactionsPaginate();
    }, [getUserTransactionsPaginate]);

    const getSwapinAmount = (description: string) => {
        if (description.includes("vers")) {
            const amount = description.split("vers ")[1].split(" ")[0];
            return amount;
        }
        if (description.includes("en")) {
            const amount = description.split("en ")[1].split(" ")[0];
            return amount;
        }
        return "";
    };

    const map = (item: Transaction): ICSVTransaction => {
        const type = TransactionService.formatType(item.type);
        const date = UtilsService.getBeautifulFormatedDateWithTime(
            new Date(item.date),
        );

        const received_amount =
            type === "Dépôt"
                ? `${item.amount}`
                : item.type === "swapout"
                ? getSwapinAmount(item.description)
                : "";
        const sended_amount =
            type === "Retrait" || type === "Échange" ? `${item.amount}` : "";
        const transaction = new ICSVTransaction(
            type,
            date,
            received_amount,
            sended_amount,
            item.user_wallet.abbreviation,
            item.fee || 0,
            item.description,
            "",
        );

        transaction.type = TransactionService.formatType(item.type);
        transaction.date = item.date;
        transaction.wallet =
            type === "Retrait"
                ? ""
                : type === "Échange"
                ? item.target?.abbreviation || ""
                : item.user_wallet.abbreviation;
        transaction.crypto_out =
            item.type === "staking"
                ? item.user_wallet.abbreviation
                : type === "Échange"
                ? item.user_wallet.abbreviation
                : item.target?.abbreviation || "";
        transaction.fee = item.fee || 0;
        transaction.crypto_fee = "";
        transaction.platform = "Perfectnodes";
        transaction.description = item.description;
        transaction.label = "";
        return transaction;
    };

    const exportToXlsx = async () => {
        const filterCSV: DatatableFilter = {
            page: 1,
            size: 99999,
            search: search,
        };

        if (startDate) {
            filterCSV.start_date = startDate;
        }
        if (endDate) {
            filterCSV.end_date = endDate;
        }

        const res_csv = await TransactionService.getUserTransactionsPaginate(
            filterCSV,
            direction === "ASC" ? "1" : undefined,
        );

        const filtered_res_csv: Transaction[] = res_csv.data.filter(
            (elt: Transaction) =>
                elt.status === WalletHistoryStatus.COMPLETED &&
                elt.type !== WalletHistoryType.SWAPIN &&
                elt.type !== WalletHistoryType.STOP_STAKING &&
                elt.type !== WalletHistoryType.PURCHASE_FIDELITY,
        );

        if (filtered_res_csv) {
            const assetsCSV: ICSVTransaction[] = filtered_res_csv.map((item) =>
                map(item),
            );

            const setColumnWidth = (worksheet: XLSX.WorkSheet) => {
                const worksheetCol = worksheet["!cols"] || [];
                worksheetCol[0] = { wpx: 50 * 1.3 };
                worksheetCol[1] = { wpx: 100 * 1.3 };
                worksheetCol[2] = { wpx: 100 * 1.3 };
                worksheetCol[3] = { wpx: 100 * 1.3 };
                worksheetCol[4] = { wpx: 100 * 1.3 };
                worksheetCol[5] = { wpx: 110 * 1.3 };
                worksheetCol[6] = { wpx: 50 * 1.3 };
                worksheetCol[7] = { wpx: 110 * 1.3 };
                worksheetCol[8] = { wpx: 70 * 1.3 };
                worksheetCol[9] = { wpx: 210 * 1.3 };
                worksheetCol[10] = { wpx: 70 * 1.3 };
                worksheet["!cols"] = worksheetCol;
            };

            const columns = [
                { label: "Type", key: "type" },
                { label: "Date", key: "date" },
                { label: "Montant reçu", key: "received_amount" },
                { label: "Monnaie ou jeton reçu", key: "wallet" },
                { label: "Montant envoyé", key: "sended_amount" },
                { label: "Monnaie ou jeton envoyé", key: "crypto_out" },
                { label: "Frais", key: "fee" },
                { label: "Monnaie ou jeton des frais", key: "crypto_fee" },
                { label: "Plateforme", key: "platform" },
                { label: "Description", key: "description" },
                { label: "Label", key: "label" },
            ];

            const sheetData = [
                columns.map((column) => column.label), // Titres de colonne
                ...assetsCSV.map((item) =>
                    columns.map(
                        (column) => item[column.key as keyof ICSVTransaction],
                    ),
                ), // Données
            ];

            const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
            const workbook = XLSX.utils.book_new();
            setColumnWidth(worksheet);
            XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

            // Génère un nom de fichier unique en ajoutant la date et l'heure actuelles
            const date = new Date()
                .toISOString()
                .slice(0, 19)
                .replace(/[-:]/g, "");
            const fileName = `transactions_${date}.xlsx`;

            XLSX.writeFile(workbook, fileName, { cellStyles: true });
        }
    };

    const handleExportClick = (e: any) => {
        e.preventDefault();
        exportToXlsx();
    };

    return (
        <>
            {isLoading && <div>Loading...</div>}
            {/* <div className="intro-y col-span-12 flex justify-end my-2 sm:flex-nowrap justify-center-mobile"></div> */}
            <DataTableWithPaginatedData
                className="table-report -mt-2 table co-hidden"
                columns={colums}
                data={assets}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                direction={direction}
                setDirection={setDirection}
                filterIndex={filterIndex}
                setFilterIndex={setFilterIndex}
                searchByDate={setDates}
                exportCSV={true}
            />

            <TransactionResponsiveTable
                className="simple-hidden"
                columns={column}
                onSearchInputChange={onSearchInputChange}
                data={assets}
                searchByDate={setDates}
                handlePerRowsChange={handlePerRowsChange}
                pageLength={nbPage}
                nbPage={nbPage}
                handlePaginationChange={handlePageChange}
                exportCSV={true}
            />
        </>
    );
};

export default TransactionTable;
