import { Menu, Transition } from "@headlessui/react";
import { User2 } from "lucide-react";
import { NavLink } from "react-router-dom";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import KpCopyClick from "../KpCopyClick";

export default function DropdownProfile({
    align,
}: {
    align?: "left" | "right";
}) {
    const { user } = useAuthentication();

    return (
        <Menu as="div" className="relative text-slate-200 inline-flex">
            {user && (
                <div className=" justify-center items-center group mr-2 hidden md:!inline-flex">
                    <span className=" ">{user?.uid}</span>
                    <KpCopyClick text={user.uid} message="" />
                </div>
            )}
            <Menu.Button className="inline-flex justify-center items-center group">
                <User2></User2>
                <div className="flex items-center truncate">
                    <svg
                        className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400"
                        viewBox="0 0 12 12"
                    >
                        <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                    </svg>
                </div>
            </Menu.Button>
            <Transition
                className={`origin-top-right z-10 absolute top-full min-w-[11rem] bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
                    align === "right" ? "right-0" : "left-0"
                }`}
                enter="transition ease-out duration-200 transform"
                enterFrom="opacity-0 -translate-y-2"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-out duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div className="pt-0.5 pb-2 px-3 mb-1 border-b border-slate-200 dark:border-slate-700">
                    <div className="font-medium text-slate-800 dark:text-slate-100">
                        {user?.first_name}
                    </div>
                    {user && (
                        <div className=" justify-center items-center group mr-2 inline-flex md:hidden">
                            <span className=" ">{user?.uid}</span>
                            <KpCopyClick text={user.uid} message="" />
                        </div>
                    )}
                    {user && user.scopes?.includes("admin") && (
                        <div className="">
                            <NavLink
                                className={`ftext-xs text-slate-500 dark:text-slate-400 italic hover:text-kalypay-500`}
                                to="admin/dashboard"
                            >
                                Administration
                            </NavLink>
                        </div>
                    )}
                </div>
                <Menu.Items as="ul" className="focus:outline-none">
                    <Menu.Item as="li">
                        {({ active }) => (
                            <NavLink
                                className={`font-medium text-sm flex items-center py-1 px-3 ${
                                    active
                                        ? "text-slate-800 dark:text-slate-400"
                                        : "text-slate-500"
                                }`}
                                to="settings/general"
                            >
                                Settings
                            </NavLink>
                        )}
                    </Menu.Item>
                    <Menu.Item as="li">
                        {({ active }) => (
                            <NavLink
                                className={`font-medium text-sm flex items-center py-1 px-3 ${
                                    active
                                        ? "text-slate-800 dark:text-slate-400"
                                        : "text-slate-500"
                                }`}
                                to="logout"
                            >
                                Sign out
                            </NavLink>
                        )}
                    </Menu.Item>
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
