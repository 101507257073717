import Sidebar from "./Sidebar";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import MobileBottomMenu from "./MobileBottomMenu";
import AppProvider from "../Provider/AppProvider";
import AssetsProvider from "../Provider/AssetsProvider";
import KpLoadingBar from "../Components/KpLoadingBar";
import CardProvider from "../Provider/CardProvider";
import ExportPdfProvider from "../../App/ExportPdfProvider";

// import "../css/style.css";

const MosaicBase = () => {
    return (
        <AppProvider>
            <CardProvider>
                <AssetsProvider>
                    <ExportPdfProvider>
                        <KpLoadingBar />
                        <div className="flex h-[100dvh] overflow-hidden bg-slate-100 dark:bg-slate-900">
                            {/* Sidebar */}
                            <Sidebar />

                            {/* Content area */}
                            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
                                {/*  Site header */}
                                <Header />

                                <Outlet />
                            </div>
                            <MobileBottomMenu />
                        </div>
                    </ExportPdfProvider>
                </AssetsProvider>
            </CardProvider>
        </AppProvider>
    );
};

export default MosaicBase;
