import { useCallback, useEffect, useState } from "react";
import { DataTableColums } from "../../../Components/Extra/DataTable/DataTable";
import User, { HeaderFilter, UserType } from "../../../Models/User";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import DataTableWithPaginatedData from "../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import PointOfSaleService from "../../../Services/Roles/PointOfSaleService/PointOfSaleService";
import ProfilComponent from "../profil/Profil";

const PointOfSaleList = () => {
    const [users, setUsers] = useState<Array<User>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterIndex, setFilterIndex] = useState<string>();
    const [filterProcess, setFilterProcess] = useState<boolean>(false);

    const default_header_filter: HeaderFilter = {
        uid: {
            filter: false,
            is_asc: true,
        },
        type: {
            filter: true,
            is_asc: true,
        },
        name: {
            filter: false,
            is_asc: true,
        },
        email: {
            filter: false,
            is_asc: true,
        },
        created_at: {
            filter: true,
            is_asc: false,
        },
        is_active: {
            filter: true,
            is_asc: true,
        },
        kyc: {
            filter: true,
            is_asc: true,
        },
    };

    const [headerFilter, setHeaderFilter] = useState<HeaderFilter>(
        default_header_filter,
    );

    const handleChangeFilter = (filterConfig: HeaderFilter, index: string) => {
        setFilterProcess(!filterProcess);
        setFilterIndex(index);
        setHeaderFilter(filterConfig);
    };

    const getAllUsersFilter = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        if (filterIndex) {
            filter.target_index = filterIndex;
            filter.target_value = headerFilter[filterIndex].is_asc;
        }

        try {
            const res = await PointOfSaleService.getAll(filter);
            setUsers([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            setIsLoading(false);
        }
    }, [
        currentPage,
        perPage,
        search,
        headerFilter,
        filterIndex,
        filterProcess,
    ]);

    const colums: DataTableColums[] = [
        {
            index: "uid",
            title: "ID KP",
            content: (row: User) => {
                return row.uid;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.uid.filter,
        },
        // {
        //     index: "type",
        //     title: "Type",
        //     content: (row: User) => {
        //         return <> {translate("UserType", row.type || "")} </>;
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     headerFilter: headerFilter.type.filter,
        // },
        {
            index: "name",
            title: "Admin_Users.Name",
            content: (row: User) => {
                return row.type == UserType.ENTERPRISE
                    ? row.entreprise
                    : row.first_name + " " + row.last_name;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.name.filter,
        },
        {
            index: "email",
            title: "Email",
            content: (row: User) => {
                return row.email;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.email.filter,
        },
        {
            index: "ca",
            title: "CA",
            content: (row: User) => {
                return row.cap;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.cap.filter,
        },
        {
            index: "action",
            title: "Actions",
            content: (row: User) => {
                return (
                    <div className="flex items-center justify-center justify-end-mobile">
                        <ProfilComponent user={row} role={"point_of_sale"} />
                    </div>
                );
            },
            filter: false,
            className: "whitespace-nowrap text-center",
        },
    ];
    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);
    useEffect(() => {
        getAllUsersFilter();
        // finalizeProcessing();
    }, [getAllUsersFilter]);

    return (
        <>
            {isLoading && <div>Loading...</div>}
            <DataTableWithPaginatedData
                className="table-report -mt-2 table"
                columns={colums}
                data={users}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                tableHeaderFilter={headerFilter}
                handleChangeFilter={handleChangeFilter}
            />
        </>
    );
};

export default PointOfSaleList;
