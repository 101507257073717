import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useProfil } from "../../Provider/ProfilProvider";
import translator from "../../../Components/Extra/Translation/Translate";
import { UserCryptoWallet } from "../../../Models/UserWallet";
import UtilsService from "../../../Services/UtilsService";
import SwitchTex from "../../../Components/Extra/Switch/SwitchTex";
import Filter from "../../../Components/Extra/Filter/Filter";

interface LinkWalletProps {
    filter: string;
    setFilter: Dispatch<SetStateAction<string>>;
}
export const LinkWallet = (props: LinkWalletProps) => {
    return (
        <div className="flex items-center md-mt">
            <div>
                <button
                    type="button"
                    className={
                        props.filter === "all"
                            ? "btn btn-perfect actif text-sm text-white !border-none hover:border-none"
                            : "btn bg-transparent text-sm !border-none"
                    }
                    onClick={() => props.setFilter("all")}
                >
                    All
                </button>
            </div>
            <div>
                <button
                    type="button"
                    className={
                        props.filter === "crypto"
                            ? "btn btn-perfect actif text-sm text-white !border-none hover:border-none"
                            : "btn bg-transparent text-sm !border-none"
                    }
                    onClick={() => props.setFilter("crypto")}
                >
                    Crypto
                </button>
            </div>
            <div>
                <button
                    type="button"
                    className={
                        props.filter === "stablecoin"
                            ? "btn btn-perfect actif text-sm text-white !border-none hover:border-none"
                            : "btn bg-transparent text-sm !border-none"
                    }
                    onClick={() => props.setFilter("stablecoin")}
                >
                    Stablecoin
                </button>
            </div>
            <div>
                <button
                    type="button"
                    className={
                        props.filter === "fiat"
                            ? "btn btn-perfect actif text-sm text-white !border-none hover:border-none"
                            : "btn bg-transparent text-sm !border-none"
                    }
                    onClick={() => props.setFilter("fiat")}
                >
                    Fiat
                </button>
            </div>
        </div>
    );
};

export interface AdminUserTabsProps {
    id: any;
}

const UserNewWalletsItems = () => {
    const { assets } = useProfil();
    const [hideZero, setHideZero] = useState<boolean>(false);
    const [valueFilter, setValueFilter] = useState<boolean>(true);
    const [sortByAmount, setSortByAmount] = useState<boolean>(true);
    const [sortByPrice, setSortByPrice] = useState<boolean>(true);
    const [sortByStacking, setSortByStacking] = useState<boolean>(true);
    const { translate } = translator();

    const [filter, setFilter] = useState<string>("all");

    const [wallets, setWallets] = useState<Array<UserCryptoWallet>>([]);
    const [filteredWallets, setFilteredWallets] = useState<
        Array<UserCryptoWallet>
    >([]);

    const getUserCryptoWallets = async () => {
        if (assets && assets !== undefined && assets !== null) {
            setWallets(assets);
            setFilteredWallets(assets);
        }
    };

    useEffect(() => {
        getUserCryptoWallets();
    }, [assets]);

    useEffect(() => {
        setFilteredWallets([]);
        try {
            if (filter === "all") {
                setFilteredWallets(wallets);
            } else if (filter === "stablecoin") {
                setFilteredWallets(
                    wallets.filter((elt) => elt.is_stable_coin === true),
                );
            } else {
                setFilteredWallets(
                    wallets.filter((elt) => elt.type === filter),
                );
            }
        } catch (error) {
        } finally {
            if (hideZero) {
                setFilteredWallets((prevarr) =>
                    prevarr.filter((elt) => elt.wallet.solde > 0),
                );
            }
        }
    }, [filter, hideZero]);
    return (
        <div className="intro-y col-span-12">
            <div className="box p-5">
                <div className="md-flex justify-between mt-10">
                    <div className="md-flex justify-start items-center">
                        <div className="text-xl font-medium pr-10">Assets</div>
                        <div className="ml-2 md-mx">
                            <LinkWallet filter={filter} setFilter={setFilter} />
                        </div>
                    </div>
                    <div className="md-flex items-center">
                        <div className="flex mx-2 md-mt sm-justify-between">
                            <div className="mr-5">
                                {translate("Wallet_Online", "Hide_Zero")}
                            </div>
                            <div className="mx-2">
                                <SwitchTex
                                    checked={hideZero}
                                    changeState={() => setHideZero(!hideZero)}
                                    switch_on="ON"
                                    switch_off="OFF"
                                />
                            </div>
                        </div>
                        <div className="flex items-center mx-2 md-mx md-mt">
                            <label htmlFor="simple-search" className="sr-only">
                                Search
                            </label>
                            <div className="relative w-full">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg
                                        aria-hidden="true"
                                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                            clipRule="evenodd"
                                        ></path>
                                    </svg>
                                </div>
                                <input
                                    type="text"
                                    id="simple-search"
                                    className="bg-transparent border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 "
                                    placeholder="Type to search name..."
                                    onChange={(event: any) => {
                                        const search = wallets.filter(
                                            (elt: UserCryptoWallet) =>
                                                elt.name
                                                    .toUpperCase()
                                                    .includes(
                                                        event.target.value.toUpperCase(),
                                                    ),
                                        );
                                        setFilteredWallets(search);
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full mb-10 mt-5">
                    <table className="w-full text-left border border-slate-400/60 !rounded-lg py-4">
                        <thead className="text-sm thead-mobil rounded-t-lg">
                            <tr>
                                <th className="p-4 w-1/5">
                                    <div className="flex">
                                        <span className="mr-2">
                                            {translate("Wallet_Online", "Name")}
                                        </span>
                                        <Filter
                                            value={!valueFilter}
                                            changeValue={() => {
                                                setValueFilter(!valueFilter);
                                                let sorted;
                                                if (valueFilter)
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (a, b) => {
                                                                const nameA =
                                                                    a.name.toUpperCase();
                                                                const nameB =
                                                                    b.name.toUpperCase();
                                                                if (
                                                                    nameA <
                                                                    nameB
                                                                ) {
                                                                    return -1;
                                                                }
                                                                if (
                                                                    nameA >
                                                                    nameB
                                                                ) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            },
                                                        );
                                                else
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (a, b) => {
                                                                const nameA =
                                                                    a.name.toUpperCase();
                                                                const nameB =
                                                                    b.name.toUpperCase();
                                                                if (
                                                                    nameA >
                                                                    nameB
                                                                ) {
                                                                    return -1;
                                                                }
                                                                if (
                                                                    nameA <
                                                                    nameB
                                                                ) {
                                                                    return 1;
                                                                }
                                                                return 0;
                                                            },
                                                        );
                                                setFilteredWallets(sorted);
                                            }}
                                        />
                                    </div>
                                </th>
                                <th className="p-4 w-1/5">
                                    <div className="flex">
                                        <span className="mr-2">
                                            {translate(
                                                "Wallet_Online",
                                                "Solde",
                                            )}
                                        </span>
                                        <Filter
                                            value={!sortByAmount}
                                            changeValue={() => {
                                                setSortByAmount(!sortByAmount);
                                                let sorted;
                                                if (sortByAmount)
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p2.wallet
                                                                    .solde -
                                                                p1.wallet.solde,
                                                        );
                                                else
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p1.wallet
                                                                    .solde -
                                                                p2.wallet.solde,
                                                        );
                                                setFilteredWallets(sorted);
                                            }}
                                        />
                                    </div>
                                </th>
                                <th className="p-4 w-1/5">
                                    <div className="flex">
                                        <span className="mr-2">
                                            {translate(
                                                "Wallet_Online",
                                                "Pri_Evo",
                                            )}
                                        </span>
                                        <Filter
                                            value={!sortByPrice}
                                            changeValue={() => {
                                                setSortByPrice(!sortByPrice);
                                                let sorted;
                                                if (sortByPrice)
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p2.current_price -
                                                                p1.current_price,
                                                        );
                                                else
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p1.current_price -
                                                                p2.current_price,
                                                        );
                                                setFilteredWallets(sorted);
                                            }}
                                        />
                                    </div>
                                </th>
                                <th className="p-4 w-1/5">
                                    <div className="flex">
                                        <span className="mr-2">
                                            {translate(
                                                "Wallet_Online",
                                                "Investi",
                                            )}
                                        </span>
                                        <Filter
                                            value={!sortByStacking}
                                            changeValue={() => {
                                                setSortByStacking(
                                                    !sortByStacking,
                                                );
                                                let sorted;
                                                if (sortByStacking)
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p2.wallet
                                                                    .staking_solde -
                                                                p1.wallet
                                                                    .staking_solde,
                                                        );
                                                else
                                                    sorted =
                                                        filteredWallets.sort(
                                                            (p1, p2) =>
                                                                p1.wallet
                                                                    .staking_solde -
                                                                p2.wallet
                                                                    .staking_solde,
                                                        );
                                                setFilteredWallets(sorted);
                                            }}
                                        />
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="tbody-mobil">
                            {filteredWallets.map(
                                (wallet: UserCryptoWallet, index: number) => (
                                    <WalletRow
                                        wallet={wallet}
                                        index={index}
                                        key={index}
                                    />
                                ),
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserNewWalletsItems;

interface WalletRowProps {
    wallet: UserCryptoWallet;
    index: number;
}

const WalletRow = ({ wallet, index }: WalletRowProps) => {
    const price_change_percentage_24 = wallet?.price_change_percentage_24 ?? 0;
    // const is_deposit_kalypay = wallet?.is_deposit_kalypay ?? false;
    return (
        <tr
            className="data-tbody border-t border-slate-400/60 hover:bg-gray-100 text-sm"
            key={index}
        >
            <td data-label="value.data" className="md-w-25 p-2 tb-mobiles">
                <div className="flex items-center">
                    <div className="h-10 w-10">
                        <img
                            src={UtilsService.getPhotoUrl(wallet.logo)}
                            alt=""
                            className="h-full"
                        />
                    </div>
                    <div className="block pl-2">
                        <div className="text-medium text-sm">
                            {wallet.abbreviation}
                        </div>
                        <div className="text-slate-400/60 text-xs">
                            {wallet.name}
                        </div>
                    </div>
                </div>
            </td>
            <td data-label="value.data" className="md-w-25 p-2 tb-mobiles">
                <div className="block md-right-table">
                    <div className="text-medium text-sm">
                        {UtilsService.formatAmount(wallet.wallet.solde, 6)}{" "}
                        {wallet.abbreviation}
                    </div>
                    <div className="text-slate-400 text-xs">
                        $
                        {UtilsService.formatAmount(
                            wallet.wallet.solde * wallet.current_price,
                            2,
                        )}
                    </div>
                </div>
            </td>
            <td data-label="value.data" className="md-w-25 p-2 tb-mobiles">
                <div className="block md-right-table">
                    <div className="text-medium text-sm">
                        $
                        {UtilsService.formatAmount(
                            wallet.current_price ?? 0,
                            2,
                        )}
                    </div>
                    {price_change_percentage_24 !== 0 ? (
                        price_change_percentage_24 < 0 ? (
                            <div className="text-red-500 text-sm flex items-center">
                                <div>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        {" "}
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                    </svg>
                                </div>
                                <div className="text-xs">
                                    {UtilsService.formatDecimal(
                                        UtilsService.getAbsoluteValue(
                                            price_change_percentage_24,
                                        ),
                                        2,
                                    )}{" "}
                                    %
                                </div>
                            </div>
                        ) : (
                            <div className="text-green-600 text-sm flex items-center">
                                <div className="rotate-180">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        fill="currentColor"
                                        className="bi bi-caret-down-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        {" "}
                                        <path d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z" />{" "}
                                    </svg>
                                </div>
                                <div className="text-xs">
                                    {UtilsService.formatDecimal(
                                        UtilsService.getAbsoluteValue(
                                            price_change_percentage_24,
                                        ),
                                        2,
                                    )}{" "}
                                    %
                                </div>
                            </div>
                        )
                    ) : (
                        " "
                    )}
                </div>
            </td>
            <td data-label="value.data" className="md-w-25 p-2 tb-mobiles">
                <div className="block md-right-table">
                    <div className="text-medium text-sm">
                        {UtilsService.formatDecimal(
                            wallet.wallet.staking_solde,
                            6,
                        )}{" "}
                        {wallet.abbreviation}
                    </div>
                    <div className="text-slate-400 text-xs">
                        $
                        {UtilsService.formatAmount(
                            wallet.wallet.staking_solde * wallet.current_price,
                            2,
                        )}
                    </div>
                </div>
            </td>
        </tr>
    );
};
