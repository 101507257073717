import React, { useCallback } from "react";
import { useEffect, useState } from "react";
import Transaction, {
    WalletHistoryStatus,
    WalletHistoryType,
} from "../../../Models/Transaction";
import TransactionService from "../../../Services/Wallets/TransactionService";
import { formatAmount } from "../../../Services/UtilsService";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import translator from "../../../Components/Extra/Translation/Translate";
import { DataTableColums } from "../../../Components/Extra/DataTable/DataTable";
import TransactionResponsiveTable, {
    DataResponsiveTableColums,
} from "../../../Components/Extra/TableResponsive/TransactionResponsiveTable";
import DataTableWithPaginatedData from "../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import { useProfil } from "../../Provider/ProfilProvider";

const UserTransaction = () => {
    const { gettedUser } = useProfil();
    const [assets, setAssets] = useState<Array<Transaction>>([]);
    const [currentPage, setCurrentPage] = useState<number>(0);
    const [increment, setIncrementor] = useState<number>(0);
    const [direction, setDirection] = useState<"ASC" | "DESC">("DESC");
    const [filterIndex, setFilterIndex] = useState<number>(0);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOrderByDate, setIsOrderByDate] = useState<boolean>(true);
    const [startDate, setStartDate] = useState<Date | undefined>();
    const [endDate, setEndDate] = useState<Date | undefined>();
    const { translate } = translator();

    const setDates = (date1?: string, date2?: string) => {
        if (date1 && date2) {
            setStartDate(new Date(date1));
            setEndDate(new Date(date2));
        } else {
            setStartDate(undefined);
            setEndDate(undefined);
        }
    };

    const colums: DataTableColums[] = [
        {
            index: "date",
            title: "Date",
            order: true,
            sort: () => {
                setDirection(direction === "ASC" ? "DESC" : "ASC");
            },
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "Description",
            content: (row: Transaction) => {
                return row.description;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = [
                    WalletHistoryType.WITHDRAW,
                    WalletHistoryType.WITHDRAW_MANUAL,
                    WalletHistoryType.WITHDRAW_CRYPTO,
                    WalletHistoryType.WITHDRAW_KALYPAY,
                    WalletHistoryType.TRANSFERT_OUT,
                    WalletHistoryType.SWAPOUT,
                    WalletHistoryType.ORDER_PACKBOOSTER,
                    WalletHistoryType.PURCHASE_FIDELITY,
                ];

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;

                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const column: DataResponsiveTableColums[] = [
        {
            index: "date",
            title: "Date",
            content: (row: Transaction) => {
                return row.date;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "description",
            title: "Description",
            content: (row: Transaction) => {
                return row.description;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "amount",
            title: "Transaction.Amount",
            content: (row: Transaction) => {
                const type = row.type;
                const includedValues = [
                    WalletHistoryType.WITHDRAW,
                    WalletHistoryType.WITHDRAW_CRYPTO,
                    WalletHistoryType.WITHDRAW_KALYPAY,
                    WalletHistoryType.TRANSFERT_OUT,
                    WalletHistoryType.SWAPOUT,
                    WalletHistoryType.ORDER_PACKBOOSTER,
                    WalletHistoryType.PURCHASE_FIDELITY,
                ];

                const txt = `${
                    !includedValues.includes(type) ? "+ " : "- "
                } ${formatAmount(
                    row.amount,
                    row.user_wallet.type === "crypto" ? 8 : 2,
                )}`;
                let class_name = !includedValues.includes(type)
                    ? "text-green-600"
                    : "text-red-500";

                if (row.status === WalletHistoryStatus.CANCELLED) {
                    class_name = "text-red-500";
                } else if (
                    row.status === WalletHistoryStatus.PENDING ||
                    row.status === WalletHistoryStatus.INITIALIZED
                ) {
                    class_name = "text-yellow-500";
                }
                return <div className={class_name}>{txt}</div>;
            },
            filter: true,
            className: "whitespace-nowrap",
        },
        {
            index: "status",
            title: "Transaction.Status",
            content: (row: Transaction) => {
                return (
                    <div>{translate("WalletHistoryStatus", row.status)}</div>
                );
            },
            filter: true,
            className: "whitespace-nowrap",
        },
    ];

    const getUserTransactionsPaginate = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        if (startDate) {
            filter.start_date = startDate;
        }
        if (endDate) {
            filter.end_date = endDate;
        }

        if (gettedUser !== undefined && gettedUser !== null) {
            try {
                setIsOrderByDate(!isOrderByDate);
                const res =
                    await TransactionService.getUserTransactionsPaginateAdmin(
                        filter,
                        gettedUser.uid,
                        direction === "ASC" ? "1" : undefined,
                    );
                setAssets([...res.data]);
                setNbPage(res.lastPage);
            } catch (err: any) {
            } finally {
                setIsLoading(false);
            }
        }
    }, [increment]);

    useEffect(() => {
        setIncrementor(increment + 1);
    }, [currentPage, perPage, search, startDate, endDate]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [startDate, endDate]);

    useEffect(() => {
        setCurrentPage(0);
        setIncrementor(increment + 1);
    }, [direction]);

    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);
    useEffect(() => {
        getUserTransactionsPaginate();
    }, [getUserTransactionsPaginate, gettedUser]);

    return (
        <>
            {/* {isLoading && <div>Loading...</div>} */}
            {/* <div className="intro-y col-span-12 flex justify-end my-2 sm:flex-nowrap justify-center-mobile"></div> */}
            <DataTableWithPaginatedData
                className="table-report -mt-2 table co-hidden"
                columns={colums}
                data={assets}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                direction={direction}
                setDirection={setDirection}
                filterIndex={filterIndex}
                setFilterIndex={setFilterIndex}
                searchByDate={setDates}
                exportCSV={false}
            />

            <TransactionResponsiveTable
                className="simple-hidden"
                columns={column}
                onSearchInputChange={onSearchInputChange}
                data={assets}
                searchByDate={setDates}
                handlePerRowsChange={handlePerRowsChange}
                pageLength={nbPage}
                nbPage={nbPage}
                handlePaginationChange={handlePageChange}
                exportCSV={false}
            />
        </>
    );
};

export default UserTransaction;
