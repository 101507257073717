import { Menu, Transition } from "@headlessui/react";
import { NavLink } from "react-router-dom";
import translator from "../../../Components/Extra/Translation/Translate";
import { useEffect, useRef, useState } from "react";
import NotificationModel from "../../../Models/NotificationModel";
import NotificationService from "../../../Services/NotificationService/NotificationService";
import UtilsService from "../../../Services/UtilsService";
import MessageSvg from "../../../Components/Extra/Svg/MessageSvg";
import VuSvg from "../../../Components/Extra/Svg/VuSvg";

export default function DropdownNotifications({
    align,
}: {
    align?: "left" | "right";
}) {
    const { translate } = translator();
    const [newNotif, setNewNotif] = useState<boolean>(false);
    const [notificationList, setNotificationList] = useState<
        Array<NotificationModel>
    >([]);
    const getAll = async () => {
        const res = await NotificationService.getAll();
        const not_seen = res.filter(
            (elt: NotificationModel) => elt.is_seen === false,
        );
        if (not_seen.length > 0) {
            setNewNotif(true);
        } else {
            setNewNotif(false);
        }
        const limitedRes = res.slice(0, 10);
        setNotificationList(limitedRes);
    };

    const seeNotification = async (elt: NotificationModel) => {
        if (elt.is_seen == false) {
            await NotificationService.isSeen(elt.id);
            getAll();
        }
    };
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const startTimer = () => {
        intervalRef.current = setTimeout(() => {
            // Function to be executed every 30 seconds
            getAll();

            // Restart the timer
            startTimer();
        }, 30000); // 30 seconds in milliseconds
    };

    useEffect(() => {
        getAll();
        startTimer();
    }, []);

    return (
        <Menu as="div" className="relative inline-flex">
            {({ open }) => (
                <>
                    <Menu.Button
                        className={`w-8 h-8 flex items-center justify-center bg-slate-100 hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full ${
                            open && "bg-slate-200"
                        }`}
                    >
                        <span className="sr-only">Notifications</span>
                        <svg
                            className="w-4 h-4"
                            viewBox="0 0 16 16"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="fill-current text-slate-500 dark:text-slate-400"
                                d="M6.5 0C2.91 0 0 2.462 0 5.5c0 1.075.37 2.074 1 2.922V12l2.699-1.542A7.454 7.454 0 006.5 11c3.59 0 6.5-2.462 6.5-5.5S10.09 0 6.5 0z"
                            />
                            <path
                                className="fill-current text-slate-400 dark:text-slate-500"
                                d="M16 9.5c0-.987-.429-1.897-1.147-2.639C14.124 10.348 10.66 13 6.5 13c-.103 0-.202-.018-.305-.021C7.231 13.617 8.556 14 10 14c.449 0 .886-.04 1.307-.11L15 16v-4h-.012C15.627 11.285 16 10.425 16 9.5z"
                            />
                        </svg>
                        {newNotif && (
                            <div className="absolute top-0 right-0 w-2.5 h-2.5 bg-rose-500 border-2 border-white dark:border-[#182235] rounded-full"></div>
                        )}
                    </Menu.Button>
                    <Transition
                        className={`origin-top-right z-10 absolute top-full -mr-48 xs:mr-[-128px] min-w-[20rem] bg-white dark:bg-slate-800 border border-slate-200 dark:border-slate-700 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${
                            align === "right" ? "right-0" : "left-0"
                        }`}
                        enter="transition ease-out duration-200 transform"
                        enterFrom="opacity-0 -translate-y-2"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-out duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase pt-1.5 pb-2 px-4">
                            Notifications
                        </div>
                        <Menu.Items as="ul" className="focus:outline-none">
                            {notificationList.map(
                                (elt: NotificationModel, key: number) => (
                                    <Menu.Item
                                        as="li"
                                        className="border-b border-slate-200 dark:border-slate-700 last:border-0"
                                        key={`notif-${key}`}
                                    >
                                        {({ active }) => (
                                            <NavLink
                                                className={`block py-2 px-4 ${
                                                    active &&
                                                    "bg-slate-50 dark:bg-slate-700/20"
                                                }`}
                                                to="#0"
                                                onClick={() =>
                                                    seeNotification(elt)
                                                }
                                            >
                                                <span className="flex text-sm mb-2">
                                                    <span className="flex font-medium text-slate-800 dark:text-slate-100">
                                                        {!!elt.is_seen ? (
                                                            <VuSvg />
                                                        ) : (
                                                            <MessageSvg />
                                                        )}
                                                        &nbsp;{elt.title}
                                                    </span>{" "}
                                                </span>
                                                <span className="block">
                                                    {elt.description}
                                                </span>

                                                <span className="block text-xs font-medium text-slate-400 dark:text-slate-500">
                                                    {UtilsService.getBeautifulFormatedDateWithTime(
                                                        elt.created_at,
                                                    )}
                                                </span>
                                            </NavLink>
                                        )}
                                    </Menu.Item>
                                ),
                            )}
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
}
