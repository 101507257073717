import { useCallback, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import DataTable, {
    DataTableColums,
} from "../../../Components/Extra/DataTable/DataTable";
import DeleteSvg from "../../../Components/Extra/Svg/DeleteSvg";
import User, { HeaderFilter, UserType } from "../../../Models/User";
import alert_message from "../../../Services/Alert/AlertMessage";
import UserService from "../../../Services/User/UserService";
import tranlator from "../../../Components/Extra/Translation/Translate";
import UtilsService from "../../../Services/UtilsService";
import useAuthentication from "../../../Services/Authentication/useAuthentication";
import { DatatableFilter } from "../../../Models/DatatableFilter";
import DataTableWithPaginatedData from "../../../Components/Extra/DataTable/DataTableWithPaginatedData/DataTableWithPaginatedData";
import ProfilComponent from "../profil/Profil";

const UserClientTable = () => {
    const { user } = useAuthentication();
    const { translate } = tranlator();
    const [users, setUsers] = useState<Array<User>>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = useState<number>(10);
    const [nbPage, setNbPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterIndex, setFilterIndex] = useState<string>();
    const [filterProcess, setFilterProcess] = useState<boolean>(false);

    const default_header_filter: HeaderFilter = {
        uid: {
            filter: false,
            is_asc: true,
        },
        type: {
            filter: true,
            is_asc: true,
        },
        name: {
            filter: false,
            is_asc: true,
        },
        email: {
            filter: false,
            is_asc: true,
        },
        created_at: {
            filter: true,
            is_asc: false,
        },
        is_active: {
            filter: true,
            is_asc: true,
        },
        kyc: {
            filter: true,
            is_asc: true,
        },
    };

    const [headerFilter, setHeaderFilter] = useState<HeaderFilter>(
        default_header_filter,
    );

    const handleChangeFilter = (filterConfig: HeaderFilter, index: string) => {
        setFilterProcess(!filterProcess);
        setFilterIndex(index);
        setHeaderFilter(filterConfig);
    };

    const getAllUsersFilter = useCallback(async () => {
        setIsLoading(true);
        const filter: DatatableFilter = {
            page: currentPage,
            size: perPage,
            search: search,
        };

        if (filterIndex) {
            filter.target_index = filterIndex;
            filter.target_value = headerFilter[filterIndex].is_asc;
        }

        try {
            const res = await UserService.getAllCustomers(filter);
            setUsers([...res.data]);
            setNbPage(res.lastPage);
        } catch (err: any) {
        } finally {
            setIsLoading(false);
        }
    }, [
        currentPage,
        perPage,
        search,
        headerFilter,
        filterIndex,
        filterProcess,
    ]);

    const colums: DataTableColums[] = [
        {
            index: "uid",
            title: "ID KP",
            content: (row: User) => {
                return row.uid;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.uid.filter,
        },
        // {
        //     index: "type",
        //     title: "Type",
        //     content: (row: User) => {
        //         return <> {translate("UserType", row.type || "")} </>;
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     headerFilter: headerFilter.type.filter,
        // },
        {
            index: "name",
            title: "Admin_Users.Name",
            content: (row: User) => {
                return row.type == UserType.ENTERPRISE
                    ? row.entreprise
                    : row.first_name + " " + row.last_name;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.name.filter,
        },
        {
            index: "email",
            title: "Email",
            content: (row: User) => {
                return row.email;
            },
            filter: true,
            className: "whitespace-nowrap",
            headerFilter: headerFilter.email.filter,
        },
        {
            index: "ca",
            title: "CA",
            content: (row: User) => {
                return row.cap;
            },
            filter: true,
            className: "whitespace-nowrap",
            // headerFilter: headerFilter.cap.filter,
        },
        // {
        //     index: "created_at",
        //     title: "Admin_Users.Inscription_date",
        //     content: (row: User) => {
        //         return row.created_at
        //             ? UtilsService.getBeautifulFormatedDate(row.created_at)
        //             : "";
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     headerFilter: headerFilter.created_at.filter,
        // },
        // {
        //     index: "is_active",
        //     title: "Status",
        //     content: (row: User) => {
        //         return row.is_active ? `active` : `inactive`;
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     headerFilter: headerFilter.is_active.filter,
        // },
        // {
        //     index: "kyc",
        //     title: "KYC",
        //     content: (row: User) => {
        //         return UserService.formatKycStatus(row.kyc || "");
        //     },
        //     filter: true,
        //     className: "whitespace-nowrap",
        //     headerFilter: headerFilter.kyc.filter,
        // },
        {
            index: "action",
            title: "Actions",
            content: (row: User) => {
                return (
                    <div className="flex items-center justify-center justify-end-mobile">
                        <ProfilComponent user={row} role={"customer"} />
                        {/* {user &&
                            ["PN74212611", "PN05216451"].includes(
                                user?.uid || "",
                            ) && (
                                <>
                                    <NavLink
                                        className="mr-3 flex items-center"
                                        to={`stake/${row.uid}`}
                                        state={{ user: row }}
                                    >
                                        <i
                                            data-lucide="check-square"
                                            className="mr-1 h-4 w-4"
                                        ></i>
                                        Staking
                                    </NavLink>
                                </>
                            )}
                        <button
                            className="text-warning flex items-center mr-3"
                            type="button"
                            onClick={(event) => resetUserKyc(event, row)}
                        >
                            {translate("Admin_Users", "reset_kyc")}
                        </button>

                        {row.uid != "KNT00000001" && (
                            <button
                                className="text-danger flex items-center"
                                type="button"
                                onClick={(event) => deleteUser(event, row)}
                            >
                                <DeleteSvg />
                                {translate("Admin_Users", "Delete")}
                            </button>
                        )}  */}
                    </div>
                );
            },
            filter: false,
            className: "whitespace-nowrap text-center",
        },
    ];
    const handlePageChange = useCallback((page: number) => {
        setCurrentPage(page + 1);
    }, []);

    const handlePerRowsChange = useCallback((newPerPage: number) => {
        setPerPage(newPerPage);
    }, []);

    const onSearchInputChange = useCallback(async (txt: string) => {
        setSearch(txt);
    }, []);
    useEffect(() => {
        getAllUsersFilter();
    }, [getAllUsersFilter]);

    return (
        <>
            {isLoading && <div>Loading...</div>}
            <DataTableWithPaginatedData
                className="table-report -mt-2 table"
                columns={colums}
                data={users}
                handlePaginationChange={handlePageChange}
                handlePerRowsChange={handlePerRowsChange}
                onSearchInputChange={onSearchInputChange}
                nbPage={nbPage}
                exportUserCSV={["PN74212611", "PN05216451"].includes(
                    user?.uid || "",
                )}
                tableHeaderFilter={headerFilter}
                handleChangeFilter={handleChangeFilter}
            />
        </>
    );
};

export default UserClientTable;
