import translator from "../../../Components/Extra/Translation/Translate";
import KpBreadCrumb from "../../Components/KpBreadCrumb";
import UserClientTable from "./UserClientTable";
const ListClientUser = () => {
    const { translate } = translator();
    return (
        <main className="grow mb-20 md:mb-5">
            <div className="px-6 sm:px-6 xl:!px-[80px] w-full  mx-auto">
                <div className="relative  h-full">
                    <KpBreadCrumb
                        title={translate("ACCOUNT_MENU", "CUSTOMER")}
                    ></KpBreadCrumb>

                    <div className="grid grid-cols-12 gap-6 mt-7">
                        <div className="col-span-12">
                            <div className="p-5 mt-3 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
                                <div className="mt-5 grid grid-cols-12 gap-6 mobile-transaction">
                                    <UserClientTable />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default ListClientUser;
