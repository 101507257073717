export interface IFidelity {
    name: string;
    configuration: string;
    price: number;
    klc_value: number;
    sub_title: string;
    is_text_klc_value: boolean;
    is_text_price: boolean;
    text_klc_value: string;
    text_price: string;
    photo?: File;
    discount_3?: number;
    discount_6?: number;
    discount_12?: number;
    discount_24?: number;
}

export interface ISubscribeFidelity {
    fidelityId: number;
    nbMonth: number;
    // gateway: any;
    wallet?: string;
}

export default class FidelityModel {
    id?: number;
    configuration!: string;
    name!: string;
    price!: number;
    klc_value!: number;
    photo?: string;
    sub_title!: string;
    is_text_klc_value!: boolean;
    is_text_price!: boolean;
    text_klc_value!: string;
    text_price!: string;
    created_at?: Date;
    updated_at?: Date;
    discount_3?: number;
    discount_6?: number;
    discount_12?: number;
    discount_24?: number;

    constructor(
        name: string,
        configuration: string,
        price: number,
        klc_value: number,
    ) {
        this.name = name;
        this.configuration = configuration;
        this.price = price;
        this.klc_value = klc_value;
    }
}
export interface WithdrawReduction {
    free: boolean;
    percent_reduction: number;
}

export interface Fidelity {
    id: number;
    name: string;
    configuration: string;
    klc_value: number;
    price: number;
    photo: string;
    sub_title: string;
    is_text_klc_value: boolean;
    is_text_price: boolean;
    text_klc_value: string;
    text_price: string;
    created_at?: Date;
    updated_at?: Date;
    discount_3?: number;
    discount_6?: number;
    discount_12?: number;
    discount_24?: number;
}

export enum LoyaltyReduction {
    DEPOSIT_FIAT_FEE_REDUCTION = "red_fiat_top_fee",
    BUY_CRYPTO_FEE_REDUCTION = "red_cr_purch_fee",
    SELL_CRYPTO_FEE_REDUCTION = "red_crypto_sales_fees",
    EXCHANGE_FEE_REDUCTION = "red_active_exch_fees",
    EXTERNAL_TRANSFERT_FIAT_FEE_REDUCTION = "red_ext_fiat_transf_fees",
    PAIEMENT_MARCHAND_FEE_REDUCTION = "merchant_fee_red",
    CB_CASHBACK_KLC = "klc_cashback_cb",
    BOOST_EARNING_ROI = "boost_roi_earning",
    AFFILIATION_COMMISSION_DEPOSIT_FIAT = "com_on_fiat_rel_aff",
    AFFILIATION_COMMISSION_EXCHANGE = "com_exch_yr_aff",
    AFFILIATION_COMMISSION_EXTERNAL_TRANSFERT_FIAT = "com_ext_fiat_trans_y_aff",
    AFFILIATION_COMMISSION_EARNING = "com_earning_aff",
    LOAN_INTEREST_REDUCTION = "loan_crypto_int_red",
    EURO_IBAN = "obtaining_iban_euro",
    STERLING_IBAN = "obt_iban_pound",
    DOLLAR_IBAN = "obt_iban_dollar",
}

export type AdvantageData = {
    [key in LoyaltyReduction]: number;
};
