import {
    CRYPTO_TYPE,
    MOBILE_TYPE,
    OUT_TRANSACTIONS,
    PAYEER_TYPE,
    PM_TYPE,
    WalletHistoryType,
} from "../../../../Models/Transaction";

export const TransactionsProperties = () => {
    const statusColor = (status: string): string => {
        switch (status) {
            case "completed":
                return "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400";
            case "canceled":
            case "cancelled":
                return "bg-rose-100 dark:bg-rose-500/30 text-rose-500 dark:text-rose-400";
            default:
                return "bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400";
        }
    };

    const statusTextColor = (status: string): string => {
        switch (status) {
            case "completed":
                return " text-emerald-600 ";
            case "canceled":
            case "cancelled":
                return " text-rose-500 ";
            case "in_progress":
            case "in_process":
                return " text-yellow-500 ";
            default:
                return " text-slate-500 ";
        }
    };

    const amountColor = (amount: string): string => {
        switch (amount.charAt(0)) {
            case "+":
                return "text-emerald-500";
            default:
                return "text-red-800 dark:text-red-300";
        }
    };

    const transactionSign = (type: WalletHistoryType): string => {
        return OUT_TRANSACTIONS.includes(type) ? "-" : "+";
    };

    const isMobile = (type: WalletHistoryType): boolean => {
        return MOBILE_TYPE.includes(type);
    };

    const isPayeer = (type: WalletHistoryType): boolean => {
        return PAYEER_TYPE.includes(type);
    };

    const isPm = (type: WalletHistoryType): boolean => {
        return PM_TYPE.includes(type);
    };

    const isCrypto = (type: WalletHistoryType): boolean => {
        return CRYPTO_TYPE.includes(type);
    };

    const isVirementIn = (type: WalletHistoryType): boolean => {
        return type === WalletHistoryType.DEPOSIT_VIREMENT;
    };

    const isVirementOut = (type: WalletHistoryType): boolean => {
        return type === WalletHistoryType.WITHDRAW_VIREMENT;
    };

    return {
        statusColor,
        amountColor,
        transactionSign,
        isMobile,
        isPayeer,
        isPm,
        isCrypto,
        isVirementIn,
        isVirementOut,
        statusTextColor,
    };
};
