import { components } from "react-select";

export default class SelectOption {
    label: string;
    value: string;
    image: string;

    constructor(label: string, value: string, image: string) {
        this.label = label;
        this.value = value;
        this.image = image;
    }
}

export const OptionImage: React.FC<SelectOption> = (props) => {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <img
                src={props.image}
                style={{
                    width: 60,
                    height: 30,
                    marginRight: 10,
                    borderRadius: "50%",
                    objectFit: "contain",
                }}
            />
            {props.label}
        </div>
    );
};

export const CustomOption = (props: any) => {
    return (
        <components.Option {...props} className="bg-select-view">
            <OptionImage {...props.data} />
        </components.Option>
    );
};

export const SingleOption = (props: any) => {
    return (
        <components.SingleValue {...props}>
            <OptionImage {...props.data} />
        </components.SingleValue>
    );
};
