import { useLocation } from "react-router-dom";
import Ecosystem from "../../../Models/Ecosystem";
import { useEffect, useState } from "react";
import EcosystemService from "../../../Services/EcosystemService/EcosystemService";

const EcosystemItem = () => {
    const location = useLocation();
    const { ecosystem } = location.state || {};
    const [data, setData] = useState<Ecosystem | undefined>();
    const getFirstEcosystem = async () => {
        try {
            const res = await EcosystemService.getFirstEcosystem();
            setData(res);
        } catch (err: any) {}
    };
    useEffect(() => {
        if (!ecosystem) {
            getFirstEcosystem();
        } else {
            setData(ecosystem);
        }
    }, [ecosystem]);
    return data ? (
        <div className="col-span-12">
            <div className="intro-y box">
                <div className="-mx-5 flex flex-col border-b border-slate-200/60 pb-5 lg:flex-row relative p-3">
                    <div className="mt-6 flex-1 border-t border-slate-200/60 px-5 pt-5 lg:mt-0 lg:border-t-0 lg:pt-0">
                        <div>
                            <span>
                                <h3 className="text-center">{data.title}</h3>
                            </span>
                            <p className="text-center m-4">
                                {data.description}
                            </p>
                        </div>
                        <div className="mt-3">
                            <img
                                src={data.photo}
                                alt=""
                                className="list-image-none"
                            />
                        </div>
                        <div className="text-center m-4">
                            <p className="text-center m-4">
                                Vous pouvez accéder à {data.title} avec les
                                mêmes identifiants.
                            </p>
                            <span className="text-center m-4 ">
                                <a
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded-full"
                                    href={data.link}
                                >
                                    Switch vers {data.title}
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <></>
    );
};

export default EcosystemItem;
