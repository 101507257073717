import FidelityModel, {
    IFidelity,
    ISubscribeFidelity,
    WithdrawReduction,
} from "../../Models/Fidelity";
import { CARD_TYPE } from "../../Models/UserCard";
import axios from "../AxiosInstance";
import UtilsService from "../UtilsService";

export enum FidelityOrderPaymentType {
    KALYPAY = "kalypay",
    VISA = "visa",
    WALLET_FIAT = "wallet_fiat",
}

const map = (item: any): FidelityModel => {
    const fidelity = new FidelityModel(
        item.name,
        item.configuration,
        item.price,
        item.klc_value,
    );

    fidelity.created_at = item.created_at;
    fidelity.updated_at = item.updated_at;
    fidelity.photo = UtilsService.getPhotoUrl(item.photo);
    fidelity.id = item.id;
    fidelity.sub_title = item.sub_title;
    fidelity.is_text_klc_value = item.is_text_klc_value;
    fidelity.is_text_price = item.is_text_price;
    fidelity.text_klc_value = item.text_klc_value;
    fidelity.text_price = item.text_price;
    fidelity.discount_3 = item.discount_3;
    fidelity.discount_6 = item.discount_6;
    fidelity.discount_12 = item.discount_12;
    fidelity.discount_24 = item.discount_24;
    return fidelity;
};

const createFormData = (payload: IFidelity): FormData => {
    const formData = new FormData();
    formData.append("name", payload.name);
    formData.append("sub_title", payload.sub_title);
    formData.append("text_klc_value", payload.text_klc_value);
    formData.append("text_price", payload.text_price);
    formData.append("configuration", payload.configuration);
    formData.append("klc_value", `` + payload.klc_value);
    formData.append("price", `` + payload.price);
    formData.append("discount_3", `` + payload.discount_3);
    formData.append("discount_6", `` + payload.discount_6);
    formData.append("discount_12", `` + payload.discount_12);
    formData.append("discount_24", `` + payload.discount_24);

    if (payload.is_text_price) {
        formData.append("is_text_price", "1");
    }
    if (payload.is_text_klc_value) {
        formData.append("is_text_klc_value", "1");
    }

    if (payload.photo) {
        formData.append("photo", payload.photo);
    }

    return formData;
};

const getAll = async (): Promise<Array<FidelityModel>> => {
    return axios
        .get(`/fidelity`)
        .then(({ data }) => {
            const res: FidelityModel[] = data.map((item: any) => map(item));

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getById = async (id: number): Promise<FidelityModel> => {
    return axios
        .get(`/fidelity/${id}`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getAvailableCard = async (): Promise<{
    can: boolean;
    reason: string;
    message: string;
    type?: CARD_TYPE;
}> => {
    return axios
        .get(`/fidelity/available-card`)
        .then(({ data }) => {
            return data;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getLoyaltyData = async (): Promise<{
    current: FidelityModel | undefined;
    next: FidelityModel | undefined;
    last: FidelityModel | undefined;
}> => {
    return axios
        .get(`/fidelity/loyalty-data`)
        .then(({ data }) => {
            return {
                current: data.current ? map(data.current) : undefined,
                next: data.next ? map(data.next) : undefined,
                last: data.last ? map(data.last) : undefined,
            };
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getNextLoyalty = async (): Promise<FidelityModel> => {
    return axios
        .get(`/fidelity/next-loyalty`)
        .then(({ data }) => {
            const res: FidelityModel = map(data);

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const deleteById = async (id?: number) => {
    return axios
        .delete(`/fidelity/${id}`)
        .then((res) => Promise.resolve(res.data.message))
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const create = async (payload: IFidelity): Promise<FidelityModel | string> => {
    const formData = createFormData(payload);

    return axios
        .post("/fidelity", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Fidelité créé avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const update = async (
    notice_id: number,
    payload: IFidelity,
): Promise<string> => {
    const formData = createFormData(payload);

    return axios
        .put(`/fidelity/${notice_id}`, formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        })
        .then(() => {
            return Promise.resolve("Mis à jour avec succès");
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const subscribe = async (
    subscribe: ISubscribeFidelity,
    translate: any,
): Promise<any> => {
    return axios
        .post("/fidelity/subscribe", { ...subscribe })
        .then((res) => {
            return Promise.resolve(translate("Loyalty", res.data.code));
        })
        .catch((err: any) => {
            if (err.response && err.response.data && err.response.data.code) {
                return Promise.reject(
                    translate("Loyalty", err.response.data.code),
                );
            }
            return Promise.reject(err.message);
        });
};

const getUserSwapReduction = async (): Promise<number> => {
    return axios
        .get(`/fidelity/get-swap-reduction`)
        .then(({ data }) => {
            const res: number = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserPurchaseReduction = async (): Promise<number> => {
    return axios
        .get(`/fidelity/get-purchase-reduction`)
        .then(({ data }) => {
            const res: number = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const getUserWithdrawReduction = async (): Promise<WithdrawReduction> => {
    return axios
        .get(`/fidelity/get-withdraw-reduction`)
        .then(({ data }) => {
            const res: WithdrawReduction = data;

            return res;
        })
        .catch((err: any) => {
            return Promise.reject(UtilsService.getAxiosErrorMessage(err));
        });
};

const FidelityService = {
    getAll,
    deleteById,
    getById,
    create,
    update,
    getNextLoyalty,
    getLoyaltyData,
    subscribe,
    getUserSwapReduction,
    getUserPurchaseReduction,
    getUserWithdrawReduction,
    getAvailableCard,
};

export default FidelityService;
