import { ChangeEvent, useEffect, useRef, useState } from "react";
import UtilsService from "../../../../../Services/UtilsService";
import { CsvCont } from "../../../../Transactions/TransactionsTable";
import PnDateRangePicker from "../../../Input/DateRangePicker";
import translator from "../../../Translation/Translate";
import DataTablePageLength from "../pagination/DataTablePageLength";
import ExportUser from "../../../../../Admin/Components/User/List/ExportUser/ExportUser";
import PrintToPDF from "../../../../../Front/Pages/transactions/Print/ModalExport";
// import { DateRangePicker } from "rsuite";
// import "rsuite/dist/rsuite.css";

const DataTableFilter = ({
    currentData,
    tableButton,
    perPage,
    setPerPage,
    handlePerRowsChange,
    onSearchInputChange,
    startDate,
    endDate,
    search,
    exportCSV,
    exportPDF,
    exportUserCSV,
}: {
    currentData: any[];
    tableButton?: React.ReactNode;
    perPage: number;
    setPerPage: (page: number) => void;
    handlePerRowsChange?: (newPerPage: number) => void;
    onSearchInputChange?: (findText: string) => void;
    startDate?: string;
    endDate?: string;
    search?: (startDate?: string, endDate?: string) => void;
    exportCSV?: boolean;
    exportUserCSV?: boolean;
    exportPDF?: boolean;
}) => {
    const [filter, setFilter] = useState<string>();
    const { translate } = translator();

    const [dates, setDates] = useState<{
        startDate: string | undefined;
        endDate: string | undefined;
    }>({ startDate: undefined, endDate: undefined });

    return (
        <div className="intro-y col-span-12 flex flex-wrap justify-between items-center sm:flex-nowrap justify-center-mobile trans-phone">
            {tableButton}
            <div className="">
                <span className="mx-2">{translate("DataTable", "Show")}</span>
                <DataTablePageLength
                    perPage={perPage}
                    setPerPage={setPerPage}
                    handlePerRowsChange={handlePerRowsChange}
                />
                <span className="mx-2">{translate("DataTable", "Entree")}</span>
            </div>
            <div className="mx-auto text-slate-500 md:block">
                {search && (
                    <>
                        <div className="md-flex md:space-x-2 my-2">
                            <PnDateRangePicker
                                setDates={setDates}
                                dates={dates}
                            ></PnDateRangePicker>
                            <button
                                type="button"
                                className="btn btn-perfect my-1 md-w-full"
                                onClick={() => {
                                    if (typeof search === "function") {
                                        search(
                                            dates.startDate || "",
                                            dates.endDate || "",
                                        );
                                    }
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-search"
                                >
                                    <circle cx="11" cy="11" r="8"></circle>
                                    <line
                                        x1="21"
                                        x2="16.65"
                                        y1="21"
                                        y2="16.65"
                                    ></line>
                                </svg>
                            </button>
                            <button
                                className="btn btn-perfect md-w-full my-1 !hidden"
                                type="button"
                                onClick={() => {
                                    if (search) search();
                                }}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-eye"
                                >
                                    <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                                    <circle cx="12" cy="12" r="3" />
                                </svg>
                                {/* {translate("DataTable", "All_view")} */}
                            </button>
                            {/* {!!exportCSV && <CsvCont />} */}
                            {!!exportPDF && <PrintToPDF dates={dates} />}
                        </div>
                        <div className="!hidden">
                            {translate("DataTable", "Affichage")}
                            {currentData.length}
                            {translate("DataTable", "Element")}
                            {currentData.length}
                            {translate("DataTable", "Entree")}
                        </div>
                    </>
                )}
                {!!exportUserCSV && (
                    <div className="md-flex md:space-x-2 my-2">
                        <ExportUser />
                    </div>
                )}
            </div>
            <div className="mt-3 w-full sm:mt-0 sm:ml-auto sm:w-auto md:ml-0">
                <div className="relative w-56 text-slate-500 input-search-mobile">
                    <input
                        type="text"
                        value={filter ? filter : ""}
                        onChange={(e) => {
                            setFilter(e.target.value);
                            if (onSearchInputChange)
                                onSearchInputChange(e.target.value);
                        }}
                        className="form-control box w-56 pr-10"
                        placeholder={translate("DataTable", "Search")}
                    />
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="absolute lucide lucide-search right-2 top-2"
                    >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default DataTableFilter;
