import { Navigate, Outlet, useLocation } from "react-router-dom";
import Landing from "../../Layouts/Landing/Landing";

import useAuthentication from "../../Services/Authentication/useAuthentication";

interface IProps {
    children?: JSX.Element;
}

export default function AnonymousRouteProvider({ children }: IProps) {
    const { isAuthenticated } = useAuthentication();
    const location = useLocation();
    const { from } = location.state || { from: { pathname: "/dashboard" } };
    const redirection = from !== "/logout" ? from : "/";
    if (!isAuthenticated) {
        return children ? (
            children
        ) : (
            <Landing>
                <Outlet />
            </Landing>
        );
    } else {
        return <Navigate to={redirection} replace />;
    }
}
