import React, { useState } from "react";
import Moderation, { AdressTypeModeraton } from "../../../Models/Moderation";
import translator from "../../../Components/Extra/Translation/Translate";
import { ModalContext } from "../../../Components/Extra/Modal/ModalContext";
import UtilsService from "../../../Services/UtilsService";

const ModerationContent = ({ moderation }: { moderation: Moderation }) => {
    const { translate } = translator();
    return (
        <>
            <div className="md-flex">
                <div className="w-full p-2">
                    <h1 className="text-center font-bold uppercase">
                        {translate(
                            "Moderation",
                            "Demandes de changement de coordonnées",
                        )}
                    </h1>
                </div>
            </div>
            <div className="md-flex">
                <div className="w-full p-2">
                    <h1 className="font-bold">
                        {translate("Moderation", "Anciennes coordonnées")}
                    </h1>
                    <div>
                        {moderation.type == AdressTypeModeraton.PHONE ? (
                            <p>
                                Numero:{"+"}
                                {moderation.old_phone_country_code}{" "}
                                {moderation.old_phone}
                            </p>
                        ) : (
                            <>
                                <p>
                                    Pays:{" "}
                                    {UtilsService.getCountryName(
                                        moderation.old_country || "",
                                    )}
                                </p>
                                <p>Ville: {moderation.old_ville}</p>
                                <p>Adresse: {moderation.old_adress}</p>
                                <p>Code postale: {moderation.old_codepostal}</p>
                            </>
                        )}
                    </div>
                </div>
                <div className="w-full p-2">
                    <h1 className="font-bold">
                        {translate("Moderation", "Nouvelles coordonnées")}
                    </h1>
                    <div>
                        {moderation.type == AdressTypeModeraton.PHONE ? (
                            <p>
                                Numero:{"+"}
                                {moderation.phone_country_code}{" "}
                                {moderation.phone}
                            </p>
                        ) : (
                            <>
                                <p>
                                    Pays:{" "}
                                    {UtilsService.getCountryName(
                                        moderation.country || "",
                                    )}
                                </p>
                                <p>Ville: {moderation.ville}</p>
                                <p>Adresse: {moderation.adress}</p>
                                <p>Code postale: {moderation.codepostal}</p>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

const ButtonViewModalModeraton = ({
    moderation,
}: {
    moderation: Moderation;
}) => {
    const { translate } = translator();
    const { handleModal } = React.useContext(ModalContext);

    return (
        <button
            type="button"
            className="flex items-center mr-2"
            onClick={() =>
                handleModal(<ModerationContent moderation={moderation} />)
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#d46f1c"
                stroke-width="1.25"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="lucide lucide-eye"
            >
                <path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" />
                <circle cx="12" cy="12" r="3" />
            </svg>
        </button>
    );
};

export default ButtonViewModalModeraton;
