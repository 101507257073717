import axios from "axios";

let isRefreshing = false;
const axiosApiInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
});

let failedQueue: any[] = [];

axiosApiInstance.interceptors.request.use(
    async (config: any) => {
        return config;
    },
    (error) => {
        // Gérer l'erreur
        return Promise.reject(error);
    },
);

const processQueue = (error: any, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

export const setInstanceTokens = (token?: string) => {
    if (token) {
        axiosApiInstance.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${token}`;
    } else {
        delete axiosApiInstance.defaults.headers.common["Authorization"];
    }
};

axiosApiInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalRequest = error.config;
        if (
            error.response &&
            error.response.status === 401 &&
            // eslint-disable-next-line no-underscore-dangle
            !originalRequest._retry
        ) {
            if (isRefreshing) {
                return new Promise((resolve, reject) => {
                    failedQueue.push({ resolve, reject });
                })
                    .then(() => {
                        return axiosApiInstance(originalRequest);
                    })
                    .catch((err) => {
                        return Promise.reject(err);
                    });
            }

            isRefreshing = true;
            // eslint-disable-next-line no-underscore-dangle
            originalRequest._retry = true;
            try {
                const result = await axios.post(
                    `${process.env.REACT_APP_API_URL}/auth/refresh-token`,
                    {},
                    {
                        withCredentials: true,
                    },
                );
                processQueue(null, result.data.token);
                originalRequest.headers.Authorization = `Bearer ${result.data.token}`;
                setInstanceTokens(result.data.token);
                return await axiosApiInstance(originalRequest);
            } catch (err) {
                processQueue(err, null);
                return await Promise.reject(err);
            } finally {
                isRefreshing = false;
            }
        }
        return Promise.reject(error);
    },
);

export default axiosApiInstance;
