import loadable from "@loadable/component";

import ConfigAsset from "../Admin/Components/Assets/ConfigAsset/ConfigAsset";
import EcosystemList from "../Admin/Components/Ecosystem/EcosystemList";
import EcosystemNew from "../Admin/Components/Ecosystem/EcosystemNew";
import LoyaltyConfig from "../Admin/Components/PackBoosterOrder/LoyaltyConfig";
import ListMerchantCategory from "../Admin/Components/Roles/Merchant/Category/ListMerchantCategory";
import MerchantCategoryNew from "../Admin/Components/Roles/Merchant/Category/MerchantCategoryNew";
import AssetConfig from "../Admin/Components/Setting/Asset/AssetConfig";
import GatewayConfig from "../Admin/Components/Setting/Gateway/GatewayConfig";
import BaseAdmin from "../Admin/Layout/BaseAdmin";
import AdminRouteProvider from "./RouteProvider/AdminRouteProvider";

const DashboardAdmin = loadable(
    () => import("../Admin/Components/Dashboard/DashboardAdmin"),
);

const FidelityNew = loadable(
    () => import("../Admin/Components/Fidelity/FidelityNew"),
);
const GovernanceNew = loadable(
    () => import("../Admin/Components/Governance/GovernanceNew"),
);
const GovernanceList = loadable(
    () => import("../Admin/Components/Governance/GovernanceList"),
);
const AssetsEarningNew = loadable(
    () =>
        import("../Admin/Components/Assets/DragNdropEarning/AssetsEarningNew"),
);
const Rearrangement = loadable(
    () => import("../Admin/Components/Assets/ListAssetsEarning"),
);
const VirementConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Virement/VirementConfig"),
);

const FidelityList = loadable(
    () => import("../Admin/Components/Fidelity/FidelityList"),
);

const AdminWallets = loadable(
    () => import("../Admin/Components/Wallets/AdminWallets"),
);

const AdminListTransactions = loadable(
    () => import("../Admin/Components/Transactions/ListTransactions"),
);

const AssetsTable = loadable(
    () => import("../Admin/Components/Assets/AssetsTable"),
);

const AssetsDragDrop = loadable(
    () => import("../Admin/Components/Assets/DragNdrop/AssetsDragDrop"),
);

const AdminListStackings = loadable(
    () => import("../Admin/Components/Stacking/ListStackings"),
);

const CryptoCategoryNew = loadable(
    () => import("../Admin/Components/Assets/Category/CryptoCategoryNew"),
);

const ListCryptoCategory = loadable(
    () => import("../Admin/Components/Assets/Category/ListCryptoCategory"),
);

const CardAdmin = loadable(() => import("../Admin/Components/Cards/CardAdmin"));

const ListUser = loadable(
    () => import("../Admin/Components/User/List/ListUser"),
);

const ListAssets = loadable(
    () => import("../Admin/Components/Assets/ListAssets"),
);
const ListAllCryptos = loadable(
    () => import("../Admin/Components/Assets/ListCryptos"),
);
const AssetsNew = loadable(
    () => import("../Admin/Components/Assets/AssetsNew"),
);

const ProductNew = loadable(
    () => import("../Admin/Components/Product/Product/ProductNew"),
);

const ListProducts = loadable(
    () => import("../Admin/Components/Product/Product/ListProducts"),
);

const ProductCategoryNew = loadable(
    () => import("../Admin/Components/Product/Category/ProductCategoryNew"),
);

const ListProductCategory = loadable(
    () => import("../Admin/Components/Product/Category/ListProductCategory"),
);

const ListUnilevels = loadable(
    () => import("../Admin/Components/Unilevel/ListUnilevel"),
);

const UnilevelNew = loadable(
    () => import("../Admin/Components/Unilevel/UnilevelNew"),
);

const ListRank = loadable(() => import("../Admin/Components/Rank/ListRank"));

const RankNew = loadable(() => import("../Admin/Components/Rank/RankNew"));

const ListDuration = loadable(
    () => import("../Admin/Components/Setting/Asset/Duration/ListDuration"),
);
const TransactionForm = loadable(
    () => import("../Admin/Components/User/List/TransactionForm"),
);

const UserStakingForm = loadable(
    () => import("../Admin/Components/User/Staking/UserStakingForm"),
);

const PackBoosterAdminComponent = loadable(
    () => import("../Admin/Components/User/PackBooster/PackBooster"),
);

const ListWithdrawalRequest = loadable(
    () => import("../Admin/Components/WithdrawalRequest/ListWithdrawalRequest"),
);

const KycSetting = loadable(
    () => import("../Admin/Components/Setting/Kyc/Kyc"),
);
const AgentAdvantage = loadable(
    () => import("../Admin/Components/Setting/AgentAdvantage/AgentAdvantage"),
);

// setting

const FiatSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Fiat/Fiat"),
);
const KNTSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/KNT/KNT"),
);
const SwapSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Swap/Swap"),
);
const TransferSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Transfer/Transfer"),
);
const PositionSetting = loadable(
    () => import("../Admin/Components/Setting/Asset/Position/Position"),
);
const CryptoConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Crypto/CryptoConfig"),
);
const KalypayConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Kalypay/KalypayConfig"),
);
const VisaConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Visa/VisaConfig"),
);

const DepositTransaction = loadable(
    () => import("../Admin/Components/DepositTransactions/List"),
);

const ManualDepositTransaction = loadable(
    () => import("../Admin/Components/ManualDepositTransactions/List"),
);

const ListPools = loadable(() => import("../Admin/Components/Pools/ListPools"));

const PoolNew = loadable(() => import("../Admin/Components/Pools/PoolNew"));

const NoticeList = loadable(
    () => import("../Admin/Components/Notice/NoticeList"),
);

const NoticeNew = loadable(
    () => import("../Admin/Components/Notice/NoticeNew"),
);

const RoiTable = loadable(
    () => import("../Admin/Components/RoiTransactions/RoiTable"),
);

const SupportList = loadable(
    () => import("../Admin/Components/Support/SupportList"),
);

const SupportForm = loadable(
    () => import("../Admin/Components/Support/SupportNew"),
);

const AdminAccounting = loadable(
    () => import("../Admin/Components/Wallets/AdminAccounting"),
);

const HighNoticeList = loadable(
    () => import("../Admin/Components/HighNotice/HighNoticeList"),
);

const HighNoticeNew = loadable(
    () => import("../Admin/Components/HighNotice/HighNoticeNew"),
);

const UserLoalty = loadable(
    () => import("../Admin/Components/PackBoosterOrder/UserLoality"),
);
const Fidelity = loadable(
    () => import("../Admin/Components/PackBoosterOrder/Fidelity"),
);

const ListCurrency = loadable(
    () => import("../Admin/Components/BasicData/Currency/CurrencyList"),
);
const NewCurrency = loadable(
    () => import("../Admin/Components/BasicData/Currency/NewCurrency"),
);
const ListMerchant = loadable(
    () => import("../Admin/Components/Roles/Merchant/ListMerchant"),
);
const ListAdvisor = loadable(
    () => import("../Admin/Components/Roles/Advisor/ListAdvisor"),
);
const ListFinancialAdmin = loadable(
    () => import("../Admin/Components/Roles/FinancialAdmin/ListFinancialAdmin"),
);
const ListSaleManager = loadable(
    () => import("../Admin/Components/Roles/SaleManager/ListSaleManager"),
);
const ListCommercial = loadable(
    () => import("../Admin/Components/Roles/Commercial/ListCommercial"),
);
const ListPointOfSale = loadable(
    () => import("../Admin/Components/Roles/PointOfSale/ListPointOfSale"),
);

const ListFiat = loadable(() => import("../Admin/Components/Fiat/ListFiat"));
const NewFiat = loadable(() => import("../Admin/Components/Fiat/NewFiat"));
const ListOrderCard = loadable(
    () => import("../Admin/Components/OrderCard/ListOrderCard"),
);
const MobileConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Mobile/MobileConfig"),
);
const OrangeGuineeConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/Mobile/OrangeGuinee/OrangeGuineeConfig"
        ),
);
const IntouchConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/Mobile/Intouch/IntouchConfig"
        ),
);
const IntechConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/Mobile/Intech/IntechConfig"
        ),
);
const PerfectMoneyConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/PerfectMoney/PerfectMoneyConfig"
        ),
);
const PayeerConfig = loadable(
    () =>
        import("../Admin/Components/Setting/Gateway/PayeerConfig/PayeerConfig"),
);
const GPTConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/GPT/GPTConfig"),
);
const ConfigurationConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/Gateway/Mobile/Configuration/ConfigurationConfig"
        ),
);
const ColdWalletOrderList = loadable(
    () => import("../Admin/Components/ColdWalletOrder/ColdWalletOrderList"),
);
const ColdWalletSetting = loadable(
    () =>
        import(
            "../Admin/Components/ColdWalletOrder/ColdWalletSetting/ColdWallet"
        ),
);
const ConfigFiat = loadable(
    () => import("../Admin/Components/Fiat/ConfigFiat"),
);
const ListAssetsEarning = loadable(
    () => import("../Admin/Components/Assets/ListAssetsEarning"),
);
const AlchemyConfig = loadable(
    () => import("../Admin/Components/Setting/Gateway/Alchemy/AlchemyConfig"),
);
const ListPartnershipRequest = loadable(
    () =>
        import("../Admin/Components/PartnershipRequest/ListPartnershipRequest"),
);
const GeneralSetting = loadable(
    () => import("../Admin/Components/Setting/General/General"),
);
const GeneralConfig = loadable(
    () => import("../Admin/Components/Setting/General/GeneralConfig"),
);
const WithdrawConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/General/WitndrawConfig/WithdrawConfig"
        ),
);
const DepositConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/General/DepositConfig/DepositConfig"
        ),
);
const InternalTransferConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/General/InternalTransferConfig/InternalTransferConfig"
        ),
);
const ExternalTransferConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/General/ExternalTransferConfig/ExternalTransferConfig"
        ),
);
const AirtimeConfig = loadable(
    () =>
        import(
            "../Admin/Components/Setting/General/AirtimeConfig/AirtimeConfig"
        ),
);

const AdminRoutes = [
    {
        element: <AdminRouteProvider />,
        children: [
            {
                element: <BaseAdmin />,
                path: "/admin",
                children: [
                    {
                        path: "dashboard",
                        element: <DashboardAdmin />,
                    },
                    {
                        path: "wallet-detail",
                        element: <AdminWallets />,
                    },
                    {
                        path: "accounting",
                        element: <AdminAccounting />,
                    },
                    {
                        path: "services",
                        element: <AdminListStackings />,
                    },
                    {
                        path: "card",
                        element: <CardAdmin />,
                    },
                    {
                        path: "user",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListUser />,
                            },
                            {
                                path: "transact/:id",
                                element: <TransactionForm />,
                            },
                            {
                                path: "stake/:user_id",
                                element: <UserStakingForm />,
                            },
                            {
                                path: "booster/:uid",
                                element: <PackBoosterAdminComponent />,
                            },
                        ],
                    },
                    {
                        path: "deposit",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <DepositTransaction />,
                            },
                        ],
                    },
                    {
                        path: "deposit-request",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ManualDepositTransaction />,
                            },
                        ],
                    },
                    {
                        path: "staking-roi",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <RoiTable />,
                            },
                        ],
                    },
                    {
                        path: "assets",
                        children: [
                            {
                                element: <ListAssets />,
                                children: [
                                    {
                                        path: "list",
                                        element: <AssetsTable />,
                                    },
                                ],
                            },
                            {
                                path: "rearrange",
                                element: <ListAllCryptos />,
                            },
                            {
                                path: "new",
                                element: <AssetsNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <AssetsNew />,
                            },
                            {
                                path: "config/:abbr",
                                element: <ConfigAsset />,
                            },
                        ],
                    },
                    {
                        path: "assets-earning",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListAssetsEarning />,
                            },
                            {
                                path: "configure/:id",
                                element: <AssetsEarningNew />,
                            },
                        ],
                    },
                    {
                        path: "rearrangement",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListAssetsEarning />,
                            },
                        ],
                    },
                    {
                        path: "pools",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListPools />,
                            },
                            {
                                path: "new",
                                element: <PoolNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <PoolNew />,
                            },
                        ],
                    },
                    {
                        path: "all-transactions",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <AdminListTransactions />,
                            },
                        ],
                    },
                    {
                        path: "withdrawal-request",
                        element: <ListWithdrawalRequest />,
                    },
                    {
                        path: "card",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <FidelityList />,
                            },
                            {
                                path: "new",
                                element: <FidelityNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <FidelityNew />,
                            },
                        ],
                    },
                    {
                        path: "subscription",
                        children: [
                            {
                                path: "",
                                element: <LoyaltyConfig />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <UserLoalty />,
                                    },
                                    {
                                        path: "users",
                                        index: true,
                                        element: <UserLoalty />,
                                    },
                                    {
                                        path: "fidelities",
                                        element: <Fidelity />,
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        path: "product",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListProducts />,
                            },
                            {
                                path: "new",
                                element: <ProductNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <ProductNew />,
                            },
                        ],
                    },

                    {
                        path: "product-category",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListProductCategory />,
                            },
                            {
                                path: "new",
                                element: <ProductCategoryNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <ProductCategoryNew />,
                            },
                        ],
                    },

                    {
                        path: "crypto-category",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListCryptoCategory />,
                            },
                            {
                                path: "new",
                                element: <CryptoCategoryNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <CryptoCategoryNew />,
                            },
                        ],
                    },
                    {
                        path: "merchant-category",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListMerchantCategory />,
                            },
                            {
                                path: "new",
                                element: <MerchantCategoryNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <MerchantCategoryNew />,
                            },
                        ],
                    },
                    {
                        path: "ecosystem",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <EcosystemList />,
                            },
                            {
                                path: "new",
                                element: <EcosystemNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <EcosystemNew />,
                            },
                        ],
                    },
                    {
                        path: "unilevel",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListUnilevels />,
                            },
                            {
                                path: "new",
                                element: <UnilevelNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <UnilevelNew />,
                            },
                        ],
                    },
                    {
                        path: "rank",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListRank />,
                            },
                            {
                                path: "new",
                                element: <RankNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <RankNew />,
                            },
                        ],
                    },
                    {
                        path: "setting",
                        children: [
                            {
                                path: "general",
                                element: <GeneralConfig />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <GeneralSetting />,
                                    },
                                    {
                                        path: "general",
                                        index: true,
                                        element: <GeneralSetting />,
                                    },
                                    {
                                        path: "withdraw",
                                        index: true,
                                        element: <WithdrawConfig />,
                                    },
                                    {
                                        path: "deposit",
                                        index: true,
                                        element: <DepositConfig />,
                                    },
                                    {
                                        path: "internal-transfer",
                                        index: true,
                                        element: <InternalTransferConfig />,
                                    },
                                    {
                                        path: "external-transfer",
                                        index: true,
                                        element: <ExternalTransferConfig />,
                                    },
                                    {
                                        path: "airtime",
                                        index: true,
                                        element: <AirtimeConfig />,
                                    },
                                ],
                            },
                            {
                                path: "asset",
                                element: <AssetConfig />,
                                children: [
                                    {
                                        path: "",
                                        index: true,
                                        element: <KNTSetting />,
                                    },
                                    {
                                        path: "klc",
                                        index: true,
                                        element: <KNTSetting />,
                                    },
                                    {
                                        path: "fiat",
                                        element: <FiatSetting />,
                                    },
                                    {
                                        path: "swap",
                                        element: <SwapSetting />,
                                    },
                                    {
                                        path: "transfer",
                                        element: <TransferSetting />,
                                    },
                                    // {
                                    //     path: "position",
                                    //     element: <PositionSetting />,
                                    // },
                                ],
                            },
                            {
                                path: "kyc",
                                element: <KycSetting />,
                            },
                            {
                                path: "duration",
                                element: <ListDuration />,
                            },
                            {
                                path: "advantage",
                                element: <AgentAdvantage />,
                                children: [],
                            },
                            {
                                path: "payment",
                                element: <GatewayConfig />,
                                children: [
                                    {
                                        element: <VisaConfig />,
                                        path: "",
                                        index: true,
                                    },
                                    {
                                        element: <KalypayConfig />,
                                        path: "kalypay",
                                        index: true,
                                    },
                                    {
                                        element: <VisaConfig />,
                                        path: "visa",
                                        index: true,
                                    },
                                    {
                                        element: <CryptoConfig />,
                                        path: "crypto",
                                        index: true,
                                    },
                                    {
                                        element: <VirementConfig />,
                                        path: "virement",
                                        index: true,
                                    },
                                    {
                                        path: "mobile",
                                        element: <MobileConfig />,
                                        children: [
                                            {
                                                element: <OrangeGuineeConfig />,
                                                path: "",
                                                index: true,
                                            },
                                            {
                                                element: <OrangeGuineeConfig />,
                                                path: "orange_guinee",
                                                index: true,
                                            },
                                            {
                                                element: <IntouchConfig />,
                                                path: "intouch",
                                                index: true,
                                            },
                                            {
                                                element: <IntechConfig />,
                                                path: "intech",
                                                index: true,
                                            },
                                            {
                                                element: (
                                                    <ConfigurationConfig />
                                                ),
                                                path: "configuration",
                                                index: true,
                                            },
                                        ],
                                    },
                                    {
                                        element: <PerfectMoneyConfig />,
                                        path: "perfect-money",
                                        index: true,
                                    },
                                    {
                                        element: <PayeerConfig />,
                                        path: "payeer",
                                        index: true,
                                    },
                                    {
                                        element: <GPTConfig />,
                                        path: "gpt",
                                        index: true,
                                    },
                                    {
                                        element: <AlchemyConfig />,
                                        path: "alchemy",
                                        index: true,
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        path: "notice",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <NoticeList />,
                            },
                            {
                                path: "new",
                                element: <NoticeNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <NoticeNew />,
                            },
                        ],
                    },
                    {
                        path: "governance",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <GovernanceList />,
                            },
                            {
                                path: "new",
                                element: <GovernanceNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <GovernanceNew />,
                            },
                            {
                                path: "duplicate/:id",
                                element: <GovernanceNew />,
                            },
                        ],
                    },
                    {
                        path: "high-notice",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <HighNoticeList />,
                            },
                            {
                                path: "new",
                                element: <HighNoticeNew />,
                            },
                            {
                                path: "edit/:id",
                                element: <HighNoticeNew />,
                            },
                        ],
                    },
                    {
                        path: "support",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <SupportList />,
                            },
                            {
                                path: "new",
                                element: <SupportForm />,
                            },
                            {
                                path: "edit/:id",
                                element: <SupportForm />,
                            },
                        ],
                    },
                    {
                        path: "Currency",
                        children: [
                            {
                                path: "",
                                element: <ListCurrency />,
                            },
                            {
                                path: "new",
                                element: <NewCurrency />,
                            },
                            {
                                path: "edit/:id",
                                element: <NewCurrency />,
                            },
                        ],
                    },
                    {
                        path: "merchant",
                        children: [
                            {
                                path: "",
                                element: <ListMerchant />,
                            },
                        ],
                    },
                    {
                        path: "roles",
                        children: [
                            {
                                path: "point_of_sales",
                                element: <ListPointOfSale />,
                            },
                            {
                                path: "commercial",
                                element: <ListCommercial />,
                            },
                            {
                                path: "sale_manager",
                                element: <ListSaleManager />,
                            },
                            {
                                path: "advisor",
                                element: <ListAdvisor />,
                            },
                            {
                                path: "financial_admin",
                                element: <ListFinancialAdmin />,
                            },
                        ],
                    },
                    {
                        path: "Fiat",
                        children: [
                            {
                                path: "",
                                element: <ListFiat />,
                            },
                            {
                                path: "new",
                                element: <NewFiat />,
                            },
                            {
                                path: "edit/:id",
                                element: <NewFiat />,
                            },
                            {
                                path: "config/:abbr",
                                element: <ConfigFiat />,
                            },
                        ],
                    },
                    {
                        path: "order-card",
                        children: [
                            {
                                path: "",
                                element: <ListOrderCard />,
                            },
                        ],
                    },
                    {
                        path: "cold-wallet-order",
                        children: [
                            {
                                path: "",
                                element: <ColdWalletOrderList />,
                            },
                        ],
                    },
                    {
                        path: "cold-wallet-setting",
                        children: [
                            {
                                path: "",
                                element: <ColdWalletSetting />,
                            },
                        ],
                    },
                    {
                        path: "partnership-request",
                        children: [
                            {
                                index: true,
                                path: "",
                                element: <ListPartnershipRequest />,
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default AdminRoutes;
