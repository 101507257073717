import { Outlet } from "react-router";

import HeadAdmin from "./HeadAdmin";
import SideAdmin from "./SideAdmin";

const BaseAdmin = () => {
    return (
        <div>
            <HeadAdmin />
            <div className="mdg-section flex md:mt-0">
                <SideAdmin />
                <div className="content mdg-content h-screen overflow-y-scroll w-content !z-0 !rounded-none">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default BaseAdmin;
