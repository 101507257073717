import { NavLink, useLocation } from "react-router-dom";
import { useState } from "react";
import translator from "../../Components/Extra/Translation/Translate";
import {
    KpAssetIcon,
    MosaicDashboard,
    KpTransactionIcon,
    KpCreditCardIcon,
    KpColdWalletIcon,
    KpEcosystemIcon,
    KpPaymentIcon,
    KpIbanIcon,
    KpMarkerIcon,
    KpLoyaltyIcon,
    KpEarnIcon,
    KpSettingIcon,
    KpHelpIcon,
    KpLoanIcon,
    KpPartenarIcon,
    KpOrderIcon,
    KpUsersIcon,
    KpModerationIcon,
    KpVirementIcon,
    KpResComIcon,
    KpComIcon,
    KpPosIcon,
    KpServicesIcon,
} from "../Icons/MosaicIcons";
import RoutesNames from "../../Services/RoutesNames";
import useAuthentication from "../../Services/Authentication/useAuthentication";

export type ItemListProps = {
    name: string;
    icon: any;
    path: string;
    redirectPath?: string;
    childs: ItemListProps[];
    allowed?: boolean;
    disabled?: boolean;
};

export type GroupedMenu = {
    label: string;
    menuList: ItemListProps[];
};

const ItemListComponent = ({
    name,
    icon,
    childs,
    path,
    redirectPath,
    disabled = false,
}: ItemListProps) => {
    const location = useLocation();

    const [expanded, setExpanded] = useState<boolean>(false);
    const { translate } = translator();

    const toggleExanded = (e: any) => {
        if (childs && childs.length > 0) {
            setExpanded((expanded) => !expanded);
            e.preventDefault();
            return false;
        }
    };

    return (
        <li
            className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                location.pathname.includes(path) &&
                "bg-slate-100 dark:bg-slate-900"
            }`}
        >
            {disabled ? (
                <NavLink
                    to={"#"}
                    className={`block  truncate transition duration-150 cursor-not-allowed  hover:text-kalypay-700 text-slate-400 ${
                        location.pathname.includes(path)
                            ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                            : "hover:text-kalypay-700"
                    }`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </NavLink>
            ) : (
                <NavLink
                    to={redirectPath ? redirectPath : path}
                    className={`block  truncate transition duration-150 hover:text-kalypay-700 text-slate-400 ${
                        location.pathname.includes(path)
                            ? "hover:text-kalypay-700 dark:text-slate-200  dark:hover:text-kalypay-700"
                            : "hover:text-kalypay-700"
                    }`}
                >
                    <div className="flex items-center">
                        {icon}
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200 ">
                            {translate(".", name)}
                        </span>
                    </div>
                </NavLink>
            )}
        </li>
    );
};

const GroupeMenuComponent = ({ groups }: { groups: GroupedMenu[] }) => {
    return (
        <>
            {groups.map((group, index) => (
                <div key={`menu-group-${index}`}>
                    <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                        <span
                            className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden xl:hidden text-center w-6"
                            aria-hidden="true"
                        >
                            •••
                        </span>
                        <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            {group.label}
                        </span>
                    </h3>
                    <ul className="mt-3">
                        {group.menuList.map((menu, i) =>
                            menu.allowed === undefined || menu.allowed ? (
                                <ItemListComponent
                                    key={i}
                                    name={menu.name}
                                    icon={menu.icon}
                                    path={menu.path}
                                    redirectPath={menu.redirectPath}
                                    childs={menu.childs}
                                    disabled={menu.disabled}
                                />
                            ) : null,
                        )}
                    </ul>
                </div>
            ))}
        </>
    );
};

const SideMenu = () => {
    const location = useLocation();
    const { translate } = translator();
    const {
        isAdmin,
        isFinancialAdmin,
        isMerchant,
        isSaleManager,
        isCommercial,
        isPos,
        isAdvisor,
        isAgent,
        isSU,
    } = useAuthentication();

    const AccountMenu: GroupedMenu[] = [
        {
            label: translate("ACCOUNT_MENU", "MANAGE"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.DASHBOARD",
                    path: RoutesNames.Dashboard,
                    icon: (
                        <MosaicDashboard
                            active={location.pathname.includes("dashboard")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.ASSETS",
                    path: RoutesNames.Assets,
                    icon: (
                        <KpAssetIcon
                            active={location.pathname.includes("assets")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.TRANSACTIONS",
                    path: RoutesNames.Transactions,
                    icon: (
                        <KpTransactionIcon
                            active={location.pathname.includes("transactions")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.PAYMENT",
                    path: RoutesNames.Payment,
                    icon: (
                        <KpPaymentIcon
                            active={location.pathname.includes("payment")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                },
                {
                    name: "ACCOUNT_MENU.ECOSYSTEM",
                    path: RoutesNames.Ecosystem.Index,
                    redirectPath: RoutesNames.Ecosystem.Perfectnodes,
                    icon: (
                        <KpEcosystemIcon
                            active={location.pathname.includes("ecosystem")}
                        />
                    ),
                    childs: [],
                },
                // {
                //     name: "ACCOUNT_MENU.COLDWALLET",
                //     path: RoutesNames.Coldwallet.Index,
                //     icon: (
                //         <KpEcosystemIcon
                //             active={location.pathname.includes("cold-wallet")}
                //         />
                //     ),
                //     childs: [],
                // },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "FINANCES"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.CARDS",
                    path: RoutesNames.Cards,
                    icon: (
                        <KpCreditCardIcon
                            active={location.pathname.includes("cards")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.COLD_WALLET",
                    path: RoutesNames.ColdWallet,
                    icon: (
                        <KpColdWalletIcon
                            active={location.pathname.includes("cold-wallet")}
                        />
                    ),
                    childs: [],
                    // disabled: true,
                },
                {
                    name: "ACCOUNT_MENU.IBAN",
                    path: RoutesNames.Iban,
                    icon: (
                        <KpIbanIcon
                            active={location.pathname.includes("iban")}
                        />
                    ),
                    childs: [],
                    disabled: true,
                },
                {
                    name: "ACCOUNT_MENU.EARNING",
                    path: RoutesNames.Earning,
                    icon: (
                        <KpEarnIcon
                            active={location.pathname.includes(
                                RoutesNames.Earning,
                            )}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.SERVICES",
                    path: RoutesNames.Services,
                    icon: (
                        <KpServicesIcon
                            active={location.pathname.includes(
                                RoutesNames.Services,
                            )}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.LOAN",
                    path: RoutesNames.Loan,
                    icon: (
                        <KpLoanIcon
                            active={location.pathname.includes("loan")}
                        />
                    ),
                    childs: [],
                    disabled: true,
                },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "OTHER"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.SALES_MANAGER",
                    path: RoutesNames.Sales_manager,
                    icon: (
                        <KpResComIcon
                            active={location.pathname.includes(
                                RoutesNames.Sales_manager,
                            )}
                        />
                    ),
                    childs: [],
                    allowed: isAdmin || isSU || isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.COMMERCIAL",
                    path: RoutesNames.Commercial,
                    icon: (
                        <KpComIcon
                            active={location.pathname.includes(
                                RoutesNames.Commercial,
                            )}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        // isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.POINT_OF_SALE",
                    path: RoutesNames.Point_of_sale,
                    icon: (
                        <KpPosIcon
                            active={location.pathname.includes(
                                RoutesNames.Point_of_sale,
                            )}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        // isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin ||
                        isCommercial,
                },
                {
                    name: "ACCOUNT_MENU.Customer",
                    path: RoutesNames.Customer,
                    icon: (
                        <KpUsersIcon
                            active={location.pathname.includes("customer")}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        isAgent ||
                        isSU ||
                        isSaleManager ||
                        isFinancialAdmin ||
                        isCommercial,
                },
                {
                    name: "ACCOUNT_MENU.CARD_ORDER",
                    path: RoutesNames.CardOrder,
                    icon: (
                        <KpOrderIcon
                            active={location.pathname.includes("/orders")}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin ||
                        isFinancialAdmin ||
                        isCommercial ||
                        isSaleManager,
                },
                {
                    name: "ACCOUNT_MENU.DEPOSIT_VIREMENT",
                    path: RoutesNames.DepositVirementFA,
                    icon: (
                        <KpVirementIcon
                            active={location.pathname.includes(
                                RoutesNames.DepositVirementFA,
                            )}
                        />
                    ),
                    childs: [],
                    allowed: isAdmin || isFinancialAdmin,
                },
                {
                    name: "ACCOUNT_MENU.MODERATION",
                    path: RoutesNames.Moderation,
                    icon: (
                        <KpModerationIcon
                            active={location.pathname.includes(
                                RoutesNames.Moderation,
                            )}
                        />
                    ),
                    childs: [],
                    allowed:
                        isAdmin || isSU || isSaleManager || isFinancialAdmin,
                },
            ],
        },
        {
            label: translate("ACCOUNT_MENU", "SUPPORT"),
            menuList: [
                {
                    name: "ACCOUNT_MENU.LOYALTY",
                    path: RoutesNames.Loaylty,
                    icon: (
                        <KpLoyaltyIcon
                            active={location.pathname.includes("loyalty")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.FIND_AGENT",
                    path: RoutesNames.FindAgent,
                    icon: (
                        <KpMarkerIcon
                            active={location.pathname.includes("find-agent")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.SECURTIY_AND_SETTING",
                    path: RoutesNames.Setting.Index,
                    redirectPath: RoutesNames.Setting.General,
                    icon: (
                        <KpSettingIcon
                            active={location.pathname.includes("settings")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.PARTENAR",
                    path: RoutesNames.Partners,
                    icon: (
                        <KpPartenarIcon
                            active={location.pathname.includes("partners")}
                        />
                    ),
                    childs: [],
                },
                {
                    name: "ACCOUNT_MENU.FAQ",
                    path: RoutesNames.Faq,
                    icon: (
                        <KpHelpIcon
                            active={location.pathname.includes("faq")}
                        />
                    ),
                    childs: [],
                },
            ],
        },
    ];

    return <GroupeMenuComponent groups={AccountMenu} />;
};

export default SideMenu;
