import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";
import UserNewWalletsItems from "./UserNewWalletsItems";

export interface AdminUserTabsProps {
    id: any;
}

const UserNewWallet = () => {
    const { translate } = translator();
    return (
        <>
            <div className="intro-y mt-5 mb-2 items-center">
                <h2 className="mr-auto text-2xl font-medium !capitalize">
                    {translate("My_Transactions", "WALLETS")}
                </h2>
                <hr className="mdg-bg-gardient-mode mt-2 h-1 md:w-80 lg:w-80 xl:w-80 2xl:w-80 rounded-[20px]" />
            </div>
            <div className="intro-y mt-2 hidden items-center">
                <h3 className="mr-auto text-sm">
                    <i>{translate("Fidelity", "Fidelity_Descr")}</i>
                </h3>
            </div>
            <div className="grid grid-cols-12 gap-6 mt-5">
                <UserNewWalletsItems />
            </div>
        </>
    );
};

export default UserNewWallet;
