import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import en from "../../translation/translate.en.json";
import fr from "../../translation/translate.fr.json";
import es from "../../translation/translate.es.json";
export enum Language {
    FR = "fr",
    EN = "en",
    ES = "es",
}

export interface AppLanguage {
    language: Language;
    translations: any;
    defaultTranslations: any;
}

const localLanguage = localStorage.getItem("AppLanguage") as Language;
const language = localLanguage ? localLanguage : Language.FR;

const getTranslations = (lang: Language) => {
    switch (lang) {
        case Language.EN:
            return en;
            break;
        case Language.ES:
            return es;
            break;
        default:
            return fr;
    }
};
const translations = getTranslations(language);

const initialState: AppLanguage = {
    language: language,
    translations: translations,
    defaultTranslations: en,
};

const LanguageSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        ChangeAppLanguage(
            state,
            action: PayloadAction<{ language: Language }>,
        ) {
            const language = action.payload.language;
            localStorage.setItem("AppLanguage", language);
            state.language = language;
            state.translations = getTranslations(language);
        },
    },
});

export const { ChangeAppLanguage } = LanguageSlice.actions;

export default LanguageSlice.reducer;
